@charset "UTF-8";
/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap v4.3.1 (https://getbootstrap.com)
 *
 *  Copyright 2011-2018 The Bootstrap Authors
 *  Copyright 2011-2018 Twitter, Inc.
 *  Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Global configuration
 *
 *  Here you can change main theme, enable or disable certain components and
 *  optional styles. This allows you to include only components that you need.
 *
 *  'true'  - enables component and includes it to main CSS file.
 *  'false' - disables component and excludes it from main CSS file.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom Limitless functions
 *
 *  Utility mixins and functions for evalutating source code across our variables, maps, and mixins.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom template mixins
 *
 *  All custom mixins are prefixed with "ll-" to avoid conflicts
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Default Bootstrap variable overrides
 *
 *  Variables should follow the `$component-state-property-size` formula for
 *  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 *  Also includes custom variables, all marked with "!default" flag.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Additional variables
 *
 *  Mainly 3rd party libraries and additional variables for default
 *  Bootstrap components.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Mixins
 *
 *  Import Bootstrap mixins with overrides
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Buttons mixin
 *
 *  Override and extend default buttons mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Caret mixin
 *
 *  Override and extend default cared mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Background mixin
 *
 *  Override and extend default background mixin.
 *
 * ---------------------------------------------------------------------------- */
:root {
  --blue: #2196F3;
  --blue-100: #e9f5fe;
  --red: #ef5350;
  --red-100: #fdeeee;
  --orange: #f58646;
  --orange-100: #fef3ed;
  --green: #25b372;
  --green-100: #e9f7f1;
  --cyan: #2cbacc;
  --cyan-100: #eaf8fa;
  --gray: #999;
  --gray-dark: #333;
  --primary: #2196F3;
  --primary-100: #e9f5fe;
  --secondary: #45748a;
  --secondary-100: #ecf1f3;
  --success: #25b372;
  --success-100: #e9f7f1;
  --info: #2cbacc;
  --info-100: #eaf8fa;
  --warning: #f58646;
  --warning-100: #fef3ed;
  --danger: #ef5350;
  --danger-100: #fdeeee;
  --white: #fff;
  --light: #f0f2f5;
  --light-100: rgba(255, 255, 255, 0.1);
  --dark: #252b36;
  --dark-100: rgba(0, 0, 0, 0.1);
  --teal: #26A69A;
  --teal-100: #e9f6f5;
  --indigo: #5C6BC0;
  --indigo-100: #eff0f9;
  --purple: #8e70c1;
  --purple-100: #f4f1f9;
  --pink: #f35c86;
  --pink-100: #feeff3;
  --yellow: #ffd648;
  --yellow-100: #fffbed;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 769px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5715;
  color: #333;
  text-align: left;
  background-color: #f5f5f5; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.625rem; }

p {
  margin-top: 0;
  margin-bottom: 0.625rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2196F3;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0a6ebd;
    text-decoration: none; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem 1.25rem;
  padding-bottom: 0.75rem 1.25rem;
  color: #999;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.625rem;
  font-weight: 400;
  line-height: 1.5715; }

h1, .h1 {
  font-size: 1.625rem; }

h2, .h2 {
  font-size: 1.5rem; }

h3, .h3 {
  font-size: 1.375rem; }

h4, .h4 {
  font-size: 1.25rem; }

h5, .h5 {
  font-size: 1.125rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.5715; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.5715; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.5715; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.5715; }

hr {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 0;
  border-top: 1px solid #ddd; }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.25rem 0.5rem;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 1.25rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1.25rem;
  font-size: 1rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #999; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.625rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #999; }

code {
  font-size: 0.8125rem;
  color: #c24a6b;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: #333;
  border-radius: 0.1875rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  font-size: 0.8125rem;
  color: #333; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 450px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 769px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -ms-flex: 0 0 20%;
      flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
      order: -1; }

.order-last {
  -ms-flex-order: 13;
      order: 13; }

.order-0 {
  -ms-flex-order: 0;
      order: 0; }

.order-1 {
  -ms-flex-order: 1;
      order: 1; }

.order-2 {
  -ms-flex-order: 2;
      order: 2; }

.order-3 {
  -ms-flex-order: 3;
      order: 3; }

.order-4 {
  -ms-flex-order: 4;
      order: 4; }

.order-5 {
  -ms-flex-order: 5;
      order: 5; }

.order-6 {
  -ms-flex-order: 6;
      order: 6; }

.order-7 {
  -ms-flex-order: 7;
      order: 7; }

.order-8 {
  -ms-flex-order: 8;
      order: 8; }

.order-9 {
  -ms-flex-order: 9;
      order: 9; }

.order-10 {
  -ms-flex-order: 10;
      order: 10; }

.order-11 {
  -ms-flex-order: 11;
      order: 11; }

.order-12 {
  -ms-flex-order: 12;
      order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 769px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-xl-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-xl-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-xl-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1.25rem;
  color: #333; }
  .table th,
  .table td {
    padding: 0.75rem 1.25rem;
    vertical-align: top;
    border-top: 1px solid #ddd; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd; }
  .table tbody + tbody {
    border-top: 2px solid #ddd; }

.table-sm th,
.table-sm td {
  padding: 0.625rem 1.25rem; }

.table-bordered {
  border: 1px solid #ddd; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02); }

.table-hover tbody tr:hover {
  color: #333;
  background-color: rgba(0, 0, 0, 0.03); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #e4f2fe; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #afd9fb; }

.table-hover .table-primary:hover {
  background-color: #cbe6fd; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #cbe6fd; }

.table-primary-100,
.table-primary-100 > th,
.table-primary-100 > td {
  background-color: #fcfeff; }

.table-primary-100 th,
.table-primary-100 td,
.table-primary-100 thead th,
.table-primary-100 tbody + tbody {
  border-color: #f7fbff; }

.table-hover .table-primary-100:hover {
  background-color: #e3f6ff; }
  .table-hover .table-primary-100:hover > td,
  .table-hover .table-primary-100:hover > th {
    background-color: #e3f6ff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e9eef1; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #bccdd5; }

.table-hover .table-secondary:hover {
  background-color: #d9e2e7; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d9e2e7; }

.table-secondary-100,
.table-secondary-100 > th,
.table-secondary-100 > td {
  background-color: #fdfdfe; }

.table-secondary-100 th,
.table-secondary-100 td,
.table-secondary-100 thead th,
.table-secondary-100 tbody + tbody {
  border-color: #f8fafb; }

.table-hover .table-secondary-100:hover {
  background-color: #ececf6; }
  .table-hover .table-secondary-100:hover > td,
  .table-hover .table-secondary-100:hover > th {
    background-color: #ececf6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e5f6ee; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b1e4cc; }

.table-hover .table-success:hover {
  background-color: #d2efe2; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d2efe2; }

.table-success-100,
.table-success-100 > th,
.table-success-100 > td {
  background-color: #fcfefd; }

.table-success-100 th,
.table-success-100 td,
.table-success-100 thead th,
.table-success-100 tbody + tbody {
  border-color: #f7fcfa; }

.table-hover .table-success-100:hover {
  background-color: #e9f8f0; }
  .table-hover .table-success-100:hover > td,
  .table-hover .table-success-100:hover > th {
    background-color: #e9f8f0; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e6f7f9; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #b3e6ed; }

.table-hover .table-info:hover {
  background-color: #d1f0f4; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #d1f0f4; }

.table-info-100,
.table-info-100 > th,
.table-info-100 > td {
  background-color: #fcfefe; }

.table-info-100 th,
.table-info-100 td,
.table-info-100 thead th,
.table-info-100 tbody + tbody {
  border-color: #f7fcfd; }

.table-hover .table-info-100:hover {
  background-color: #e9f8f8; }
  .table-hover .table-info-100:hover > td,
  .table-hover .table-info-100:hover > th {
    background-color: #e9f8f8; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fef0e9; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fbd3bc; }

.table-hover .table-warning:hover {
  background-color: #fddfd1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fddfd1; }

.table-warning-100,
.table-warning-100 > th,
.table-warning-100 > td {
  background-color: #fffefd; }

.table-warning-100 th,
.table-warning-100 td,
.table-warning-100 thead th,
.table-warning-100 tbody + tbody {
  border-color: #fffbf9; }

.table-hover .table-warning-100:hover {
  background-color: #fff1e4; }
  .table-hover .table-warning-100:hover > td,
  .table-hover .table-warning-100:hover > th {
    background-color: #fff1e4; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fdeaea; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f9c1c0; }

.table-hover .table-danger:hover {
  background-color: #fbd3d3; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fbd3d3; }

.table-danger-100,
.table-danger-100 > th,
.table-danger-100 > td {
  background-color: #fffdfd; }

.table-danger-100 th,
.table-danger-100 td,
.table-danger-100 thead th,
.table-danger-100 tbody + tbody {
  border-color: #fef9f9; }

.table-hover .table-danger-100:hover {
  background-color: #ffe4e4; }
  .table-hover .table-danger-100:hover > td,
  .table-hover .table-danger-100:hover > th {
    background-color: #ffe4e4; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fafafb; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-light-100,
.table-light-100 > th,
.table-light-100 > td {
  background-color: rgba(255, 255, 255, 0.892); }

.table-light-100 th,
.table-light-100 td,
.table-light-100 thead th,
.table-light-100 tbody + tbody {
  border-color: rgba(255, 255, 255, 0.676); }

.table-hover .table-light-100:hover {
  background-color: rgba(242, 242, 242, 0.892); }
  .table-hover .table-light-100:hover > td,
  .table-hover .table-light-100:hover > th {
    background-color: rgba(242, 242, 242, 0.892); }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #e5e6e7; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #b1b3b7; }

.table-hover .table-dark:hover {
  background-color: #d8d9db; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #d8d9db; }

.table-dark-100,
.table-dark-100 > th,
.table-dark-100 > td {
  background-color: rgba(253, 253, 253, 0.892); }

.table-dark-100 th,
.table-dark-100 td,
.table-dark-100 thead th,
.table-dark-100 tbody + tbody {
  border-color: rgba(248, 248, 248, 0.676); }

.table-hover .table-dark-100:hover {
  background-color: rgba(240, 240, 240, 0.892); }
  .table-hover .table-dark-100:hover > td,
  .table-hover .table-dark-100:hover > th {
    background-color: rgba(240, 240, 240, 0.892); }

.table-teal,
.table-teal > th,
.table-teal > td {
  background-color: #e5f4f3; }

.table-teal th,
.table-teal td,
.table-teal thead th,
.table-teal tbody + tbody {
  border-color: #b1dfdb; }

.table-hover .table-teal:hover {
  background-color: #d3eceb; }
  .table-hover .table-teal:hover > td,
  .table-hover .table-teal:hover > th {
    background-color: #d3eceb; }

.table-teal-100,
.table-teal-100 > th,
.table-teal-100 > td {
  background-color: #fcfefe; }

.table-teal-100 th,
.table-teal-100 td,
.table-teal-100 thead th,
.table-teal-100 tbody + tbody {
  border-color: #f7fcfb; }

.table-hover .table-teal-100:hover {
  background-color: #e9f8f8; }
  .table-hover .table-teal-100:hover > td,
  .table-hover .table-teal-100:hover > th {
    background-color: #e9f8f8; }

.table-indigo,
.table-indigo > th,
.table-indigo > td {
  background-color: #ebedf7; }

.table-indigo th,
.table-indigo td,
.table-indigo thead th,
.table-indigo tbody + tbody {
  border-color: #c4cae8; }

.table-hover .table-indigo:hover {
  background-color: #d9ddf0; }
  .table-hover .table-indigo:hover > td,
  .table-hover .table-indigo:hover > th {
    background-color: #d9ddf0; }

.table-indigo-100,
.table-indigo-100 > th,
.table-indigo-100 > td {
  background-color: #fdfdfe; }

.table-indigo-100 th,
.table-indigo-100 td,
.table-indigo-100 thead th,
.table-indigo-100 tbody + tbody {
  border-color: #f9fafd; }

.table-hover .table-indigo-100:hover {
  background-color: #ececf6; }
  .table-hover .table-indigo-100:hover > td,
  .table-hover .table-indigo-100:hover > th {
    background-color: #ececf6; }

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #f1eef8; }

.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #d6cce9; }

.table-hover .table-purple:hover {
  background-color: #e2dcf1; }
  .table-hover .table-purple:hover > td,
  .table-hover .table-purple:hover > th {
    background-color: #e2dcf1; }

.table-purple-100,
.table-purple-100 > th,
.table-purple-100 > td {
  background-color: #fefdfe; }

.table-purple-100 th,
.table-purple-100 td,
.table-purple-100 thead th,
.table-purple-100 tbody + tbody {
  border-color: #fbfafd; }

.table-hover .table-purple-100:hover {
  background-color: #f6ecf6; }
  .table-hover .table-purple-100:hover > td,
  .table-hover .table-purple-100:hover > th {
    background-color: #f6ecf6; }

.table-pink,
.table-pink > th,
.table-pink > td {
  background-color: #feebf0; }

.table-pink th,
.table-pink td,
.table-pink thead th,
.table-pink tbody + tbody {
  border-color: #fbc4d3; }

.table-hover .table-pink:hover {
  background-color: #fdd3de; }
  .table-hover .table-pink:hover > td,
  .table-hover .table-pink:hover > th {
    background-color: #fdd3de; }

.table-pink-100,
.table-pink-100 > th,
.table-pink-100 > td {
  background-color: #fffdfe; }

.table-pink-100 th,
.table-pink-100 td,
.table-pink-100 thead th,
.table-pink-100 tbody + tbody {
  border-color: #fff9fb; }

.table-hover .table-pink-100:hover {
  background-color: #ffe4f1; }
  .table-hover .table-pink-100:hover > td,
  .table-hover .table-pink-100:hover > th {
    background-color: #ffe4f1; }

.table-yellow,
.table-yellow > th,
.table-yellow > td {
  background-color: #fffae9; }

.table-yellow th,
.table-yellow td,
.table-yellow thead th,
.table-yellow tbody + tbody {
  border-color: #fff0bd; }

.table-hover .table-yellow:hover {
  background-color: #fff4d0; }
  .table-hover .table-yellow:hover > td,
  .table-hover .table-yellow:hover > th {
    background-color: #fff4d0; }

.table-yellow-100,
.table-yellow-100 > th,
.table-yellow-100 > td {
  background-color: #fffffd; }

.table-yellow-100 th,
.table-yellow-100 td,
.table-yellow-100 thead th,
.table-yellow-100 tbody + tbody {
  border-color: #fffef9; }

.table-hover .table-yellow-100:hover {
  background-color: #ffffe4; }
  .table-hover .table-yellow-100:hover > td,
  .table-hover .table-yellow-100:hover > th {
    background-color: #ffffe4; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.015); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.015); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.015); }

.table .thead-dark th {
  color: #fff;
  background-color: #333;
  border-color: rgba(255, 255, 255, 0.35); }

.table .thead-light th {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd; }

.table-dark {
  color: #fff;
  background-color: #333; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: rgba(255, 255, 255, 0.35); }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 768.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5715em + 0.875rem + 2px);
  padding: 0.4375rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5715;
  color: #333;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-shadow:;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #333; }
  .form-control:focus {
    color: #333;
    border-color: #2196F3;
    outline: 0; }
  .form-control::-webkit-input-placeholder {
    color: #999;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #999;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #999;
    opacity: 1; }
  .form-control::placeholder {
    color: #999;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #fafafa;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

select.form-control:focus::-ms-value {
  color: #333;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.4375rem + 1px);
  padding-bottom: calc(0.4375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5715; }

.col-form-label-lg {
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  font-size: 0.9375rem;
  line-height: 1.4667; }

.col-form-label-sm {
  padding-top: calc(0.3125rem + 1px);
  padding-bottom: calc(0.3125rem + 1px);
  font-size: 0.8125rem;
  line-height: 1.6924; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.4375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5715;
  color: #333;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.6924em + 0.625rem + 2px);
  padding: 0.3125rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.6924;
  border-radius: 0.1875rem; }

.form-control-lg {
  height: calc(1.4667em + 1.125rem + 2px);
  padding: 0.5625rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.4667;
  border-radius: 0.3125rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1.25rem; }

.form-text {
  display: block;
  margin-top: 0.5rem; }

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.875rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.875rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #999; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  padding-left: 0;
  margin-right: 1.25rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.625rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.8125rem;
  color: #25b372; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5715;
  color: #fff;
  background-color: #25b372;
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #25b372;
  padding-right: calc(1.5715em + 0.875rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2325b372' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.39287em + 0.21875rem) center;
  background-size: calc(0.78575em + 0.4375rem) calc(0.78575em + 0.4375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #25b372;
    box-shadow: 0 0 0 0 rgba(37, 179, 114, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5715em + 0.875rem);
  background-position: top calc(0.39287em + 0.21875rem) right calc(0.39287em + 0.21875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #25b372;
  padding-right: calc(0.75em + 2.84375rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.875rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2325b372' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2.1875rem/calc(0.78575em + 0.4375rem) calc(0.78575em + 0.4375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #25b372;
    box-shadow: 0 0 0 0 rgba(37, 179, 114, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #25b372; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #25b372; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #25b372; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #36d58c;
  background-color: #36d58c; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(37, 179, 114, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #25b372; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #25b372; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #25b372;
  box-shadow: 0 0 0 0 rgba(37, 179, 114, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.8125rem;
  color: #ef5350; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.75rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5715;
  color: #fff;
  background-color: #ef5350;
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ef5350;
  padding-right: calc(1.5715em + 0.875rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ef5350' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef5350' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.39287em + 0.21875rem) center;
  background-size: calc(0.78575em + 0.4375rem) calc(0.78575em + 0.4375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #ef5350;
    box-shadow: 0 0 0 0 rgba(239, 83, 80, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5715em + 0.875rem);
  background-position: top calc(0.39287em + 0.21875rem) right calc(0.39287em + 0.21875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ef5350;
  padding-right: calc(0.75em + 2.84375rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.875rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ef5350' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef5350' stroke='none'/%3e%3c/svg%3e") center right 2.1875rem/calc(0.78575em + 0.4375rem) calc(0.78575em + 0.4375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #ef5350;
    box-shadow: 0 0 0 0 rgba(239, 83, 80, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ef5350; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ef5350; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #ef5350; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f3817f;
  background-color: #f3817f; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(239, 83, 80, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ef5350; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ef5350; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ef5350;
  box-shadow: 0 0 0 0 rgba(239, 83, 80, 0.25); }

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -ms-flex-align: center;
      align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
      -ms-flex-align: center;
          align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #333;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.4375rem 0.875rem;
  font-size: 0.875rem;
  line-height: 1.5715;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #333;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
      box-shadow:; }
      .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus { }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #2196F3;
  border-color: #2196F3;
  box-shadow:; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0d8aee;
    border-color: #0d8aee; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0d8aee;
    border-color: #0d8aee;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(66, 166, 245, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #2196F3;
    border-color: #2196F3; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary {
    color: #fff;
    background-color: #0c7cd5;
    border-color: #0c7cd5; }

.btn-primary-100 {
  color: #333;
  background-color: #e9f5fe;
  border-color: #e9f5fe;
  box-shadow:; }
  .btn-primary-100:hover {
    color: #333;
    background-color: #d1eafd;
    border-color: #d1eafd; }
  .btn-primary-100:focus, .btn-primary-100.focus {
    color: #333;
    background-color: #d1eafd;
    border-color: #d1eafd;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(206, 216, 224, 0.5); }
  .btn-primary-100.disabled, .btn-primary-100:disabled {
    color: #333;
    background-color: #e9f5fe;
    border-color: #e9f5fe; }
  .btn-primary-100:not(:disabled):not(.disabled):active, .btn-primary-100:not(:disabled):not(.disabled).active,
  .show > .btn-primary-100 {
    color: #333;
    background-color: #b8dffc;
    border-color: #b8dffc; }

.btn-secondary {
  color: #fff;
  background-color: #45748a;
  border-color: #45748a;
  box-shadow:; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #3d6679;
    border-color: #3d6679; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #3d6679;
    border-color: #3d6679;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(97, 137, 156, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #45748a;
    border-color: #45748a; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary {
    color: #fff;
    background-color: #345768;
    border-color: #345768; }

.btn-secondary-100 {
  color: #333;
  background-color: #ecf1f3;
  border-color: #ecf1f3;
  box-shadow:; }
  .btn-secondary-100:hover {
    color: #333;
    background-color: #dce5e9;
    border-color: #dce5e9; }
  .btn-secondary-100:focus, .btn-secondary-100.focus {
    color: #333;
    background-color: #dce5e9;
    border-color: #dce5e9;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(208, 213, 214, 0.5); }
  .btn-secondary-100.disabled, .btn-secondary-100:disabled {
    color: #333;
    background-color: #ecf1f3;
    border-color: #ecf1f3; }
  .btn-secondary-100:not(:disabled):not(.disabled):active, .btn-secondary-100:not(:disabled):not(.disabled).active,
  .show > .btn-secondary-100 {
    color: #333;
    background-color: #cddadf;
    border-color: #cddadf; }

.btn-success {
  color: #fff;
  background-color: #25b372;
  border-color: #25b372;
  box-shadow:; }
  .btn-success:hover {
    color: #fff;
    background-color: #219e65;
    border-color: #219e65; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #219e65;
    border-color: #219e65;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(70, 190, 135, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #25b372;
    border-color: #25b372; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success {
    color: #fff;
    background-color: #1c8957;
    border-color: #1c8957; }

.btn-success-100 {
  color: #333;
  background-color: #e9f7f1;
  border-color: #e9f7f1;
  box-shadow:; }
  .btn-success-100:hover {
    color: #333;
    background-color: #d6f0e5;
    border-color: #d6f0e5; }
  .btn-success-100:focus, .btn-success-100.focus {
    color: #333;
    background-color: #d6f0e5;
    border-color: #d6f0e5;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(206, 218, 213, 0.5); }
  .btn-success-100.disabled, .btn-success-100:disabled {
    color: #333;
    background-color: #e9f7f1;
    border-color: #e9f7f1; }
  .btn-success-100:not(:disabled):not(.disabled):active, .btn-success-100:not(:disabled):not(.disabled).active,
  .show > .btn-success-100 {
    color: #333;
    background-color: #c4e9d9;
    border-color: #c4e9d9; }

.btn-info {
  color: #fff;
  background-color: #2cbacc;
  border-color: #2cbacc;
  box-shadow:; }
  .btn-info:hover {
    color: #fff;
    background-color: #27a7b7;
    border-color: #27a7b7; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #27a7b7;
    border-color: #27a7b7;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(76, 196, 212, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #2cbacc;
    border-color: #2cbacc; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info {
    color: #fff;
    background-color: #2394a2;
    border-color: #2394a2; }

.btn-info-100 {
  color: #333;
  background-color: #eaf8fa;
  border-color: #eaf8fa;
  box-shadow:; }
  .btn-info-100:hover {
    color: #333;
    background-color: #d5f1f5;
    border-color: #d5f1f5; }
  .btn-info-100:focus, .btn-info-100.focus {
    color: #333;
    background-color: #d5f1f5;
    border-color: #d5f1f5;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(207, 218, 220, 0.5); }
  .btn-info-100.disabled, .btn-info-100:disabled {
    color: #333;
    background-color: #eaf8fa;
    border-color: #eaf8fa; }
  .btn-info-100:not(:disabled):not(.disabled):active, .btn-info-100:not(:disabled):not(.disabled).active,
  .show > .btn-info-100 {
    color: #333;
    background-color: #c1eaf0;
    border-color: #c1eaf0; }

.btn-warning {
  color: #fff;
  background-color: #f58646;
  border-color: #f58646;
  box-shadow:; }
  .btn-warning:hover {
    color: #fff;
    background-color: #f4762e;
    border-color: #f4762e; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #f4762e;
    border-color: #f4762e;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(247, 152, 98, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
    background-color: #f58646;
    border-color: #f58646; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning {
    color: #fff;
    background-color: #f26616;
    border-color: #f26616; }

.btn-warning-100 {
  color: #333;
  background-color: #fef3ed;
  border-color: #fef3ed;
  box-shadow:; }
  .btn-warning-100:hover {
    color: #333;
    background-color: #fde3d5;
    border-color: #fde3d5; }
  .btn-warning-100:focus, .btn-warning-100.focus {
    color: #333;
    background-color: #fde3d5;
    border-color: #fde3d5;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(224, 214, 209, 0.5); }
  .btn-warning-100.disabled, .btn-warning-100:disabled {
    color: #333;
    background-color: #fef3ed;
    border-color: #fef3ed; }
  .btn-warning-100:not(:disabled):not(.disabled):active, .btn-warning-100:not(:disabled):not(.disabled).active,
  .show > .btn-warning-100 {
    color: #333;
    background-color: #fbd3bd;
    border-color: #fbd3bd; }

.btn-danger {
  color: #fff;
  background-color: #ef5350;
  border-color: #ef5350;
  box-shadow:; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ed3c39;
    border-color: #ed3c39; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #ed3c39;
    border-color: #ed3c39;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(241, 109, 106, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #ef5350;
    border-color: #ef5350; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger {
    color: #fff;
    background-color: #eb2521;
    border-color: #eb2521; }

.btn-danger-100 {
  color: #333;
  background-color: #fdeeee;
  border-color: #fdeeee;
  box-shadow:; }
  .btn-danger-100:hover {
    color: #333;
    background-color: #fad7d7;
    border-color: #fad7d7; }
  .btn-danger-100:focus, .btn-danger-100.focus {
    color: #333;
    background-color: #fad7d7;
    border-color: #fad7d7;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(223, 210, 210, 0.5); }
  .btn-danger-100.disabled, .btn-danger-100:disabled {
    color: #333;
    background-color: #fdeeee;
    border-color: #fdeeee; }
  .btn-danger-100:not(:disabled):not(.disabled):active, .btn-danger-100:not(:disabled):not(.disabled).active,
  .show > .btn-danger-100 {
    color: #333;
    background-color: #f8c0c0;
    border-color: #f8c0c0; }

.btn-white {
  color: #333;
  background-color: #fff;
  border-color: #fff;
  box-shadow:; }
  .btn-white:hover {
    color: #333;
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .btn-white:focus, .btn-white.focus {
    color: #333;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(224, 224, 224, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #333;
    background-color: #fff;
    border-color: #fff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white {
    color: #333;
    background-color: #e6e6e6;
    border-color: #e6e6e6; }

.btn-light {
  color: #333;
  background-color: #f0f2f5;
  border-color: #f0f2f5;
  box-shadow:; }
  .btn-light:hover {
    color: #333;
    background-color: #e1e5eb;
    border-color: #e1e5eb; }
  .btn-light:focus, .btn-light.focus {
    color: #333;
    background-color: #e1e5eb;
    border-color: #e1e5eb;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(212, 213, 216, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #333;
    background-color: #f0f2f5;
    border-color: #f0f2f5; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light {
    color: #333;
    background-color: #d1d7e1;
    border-color: #d1d7e1; }

.btn-light-100 {
  color: #333;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
  box-shadow:; }
  .btn-light-100:hover {
    color: #333;
    background-color: rgba(242, 242, 242, 0.1);
    border-color: rgba(242, 242, 242, 0.1); }
  .btn-light-100:focus, .btn-light-100.focus {
    color: #333;
    background-color: rgba(242, 242, 242, 0.1);
    border-color: rgba(242, 242, 242, 0.1);
    outline: 0;
    box-shadow: 0 0 0 0 rgba(98, 98, 98, 0.5); }
  .btn-light-100.disabled, .btn-light-100:disabled {
    color: #333;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1); }
  .btn-light-100:not(:disabled):not(.disabled):active, .btn-light-100:not(:disabled):not(.disabled).active,
  .show > .btn-light-100 {
    color: #333;
    background-color: rgba(230, 230, 230, 0.1);
    border-color: rgba(230, 230, 230, 0.1); }

.btn-dark {
  color: #fff;
  background-color: #252b36;
  border-color: #252b36;
  box-shadow:; }
  .btn-dark:hover {
    color: #fff;
    background-color: #1b1f27;
    border-color: #1b1f27; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #1b1f27;
    border-color: #1b1f27;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(70, 75, 84, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #252b36;
    border-color: #252b36; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark {
    color: #fff;
    background-color: #101318;
    border-color: #101318; }

.btn-dark-100 {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow:; }
  .btn-dark-100:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1); }
  .btn-dark-100:focus, .btn-dark-100.focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1);
    outline: 0;
    box-shadow: 0 0 0 0 rgba(196, 196, 196, 0.5); }
  .btn-dark-100.disabled, .btn-dark-100:disabled {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1); }
  .btn-dark-100:not(:disabled):not(.disabled):active, .btn-dark-100:not(:disabled):not(.disabled).active,
  .show > .btn-dark-100 {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1); }

.btn-teal {
  color: #fff;
  background-color: #26A69A;
  border-color: #26A69A;
  box-shadow:; }
  .btn-teal:hover {
    color: #fff;
    background-color: #219187;
    border-color: #219187; }
  .btn-teal:focus, .btn-teal.focus {
    color: #fff;
    background-color: #219187;
    border-color: #219187;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(71, 179, 169, 0.5); }
  .btn-teal.disabled, .btn-teal:disabled {
    color: #fff;
    background-color: #26A69A;
    border-color: #26A69A; }
  .btn-teal:not(:disabled):not(.disabled):active, .btn-teal:not(:disabled):not(.disabled).active,
  .show > .btn-teal {
    color: #fff;
    background-color: #1d7d74;
    border-color: #1d7d74; }

.btn-teal-100 {
  color: #333;
  background-color: #e9f6f5;
  border-color: #e9f6f5;
  box-shadow:; }
  .btn-teal-100:hover {
    color: #333;
    background-color: #d7efed;
    border-color: #d7efed; }
  .btn-teal-100:focus, .btn-teal-100.focus {
    color: #333;
    background-color: #d7efed;
    border-color: #d7efed;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(206, 217, 216, 0.5); }
  .btn-teal-100.disabled, .btn-teal-100:disabled {
    color: #333;
    background-color: #e9f6f5;
    border-color: #e9f6f5; }
  .btn-teal-100:not(:disabled):not(.disabled):active, .btn-teal-100:not(:disabled):not(.disabled).active,
  .show > .btn-teal-100 {
    color: #333;
    background-color: #c5e7e5;
    border-color: #c5e7e5; }

.btn-indigo {
  color: #fff;
  background-color: #5C6BC0;
  border-color: #5C6BC0;
  box-shadow:; }
  .btn-indigo:hover {
    color: #fff;
    background-color: #4a5ab9;
    border-color: #4a5ab9; }
  .btn-indigo:focus, .btn-indigo.focus {
    color: #fff;
    background-color: #4a5ab9;
    border-color: #4a5ab9;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(116, 129, 201, 0.5); }
  .btn-indigo.disabled, .btn-indigo:disabled {
    color: #fff;
    background-color: #5C6BC0;
    border-color: #5C6BC0; }
  .btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-indigo {
    color: #fff;
    background-color: #4150a8;
    border-color: #4150a8; }

.btn-indigo-100 {
  color: #333;
  background-color: #eff0f9;
  border-color: #eff0f9;
  box-shadow:; }
  .btn-indigo-100:hover {
    color: #333;
    background-color: #dcdff2;
    border-color: #dcdff2; }
  .btn-indigo-100:focus, .btn-indigo-100.focus {
    color: #333;
    background-color: #dcdff2;
    border-color: #dcdff2;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(211, 212, 219, 0.5); }
  .btn-indigo-100.disabled, .btn-indigo-100:disabled {
    color: #333;
    background-color: #eff0f9;
    border-color: #eff0f9; }
  .btn-indigo-100:not(:disabled):not(.disabled):active, .btn-indigo-100:not(:disabled):not(.disabled).active,
  .show > .btn-indigo-100 {
    color: #333;
    background-color: #cacdeb;
    border-color: #cacdeb; }

.btn-purple {
  color: #fff;
  background-color: #8e70c1;
  border-color: #8e70c1;
  box-shadow:; }
  .btn-purple:hover {
    color: #fff;
    background-color: #805eb9;
    border-color: #805eb9; }
  .btn-purple:focus, .btn-purple.focus {
    color: #fff;
    background-color: #805eb9;
    border-color: #805eb9;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(159, 133, 202, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #8e70c1;
    border-color: #8e70c1; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple {
    color: #fff;
    background-color: #724db1;
    border-color: #724db1; }

.btn-purple-100 {
  color: #333;
  background-color: #f4f1f9;
  border-color: #f4f1f9;
  box-shadow:; }
  .btn-purple-100:hover {
    color: #333;
    background-color: #e6dff1;
    border-color: #e6dff1; }
  .btn-purple-100:focus, .btn-purple-100.focus {
    color: #333;
    background-color: #e6dff1;
    border-color: #e6dff1;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(215, 213, 219, 0.5); }
  .btn-purple-100.disabled, .btn-purple-100:disabled {
    color: #333;
    background-color: #f4f1f9;
    border-color: #f4f1f9; }
  .btn-purple-100:not(:disabled):not(.disabled):active, .btn-purple-100:not(:disabled):not(.disabled).active,
  .show > .btn-purple-100 {
    color: #333;
    background-color: #d8cdea;
    border-color: #d8cdea; }

.btn-pink {
  color: #fff;
  background-color: #f35c86;
  border-color: #f35c86;
  box-shadow:; }
  .btn-pink:hover {
    color: #fff;
    background-color: #f14474;
    border-color: #f14474; }
  .btn-pink:focus, .btn-pink.focus {
    color: #fff;
    background-color: #f14474;
    border-color: #f14474;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(245, 116, 152, 0.5); }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #fff;
    background-color: #f35c86;
    border-color: #f35c86; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink {
    color: #fff;
    background-color: #f02c63;
    border-color: #f02c63; }

.btn-pink-100 {
  color: #333;
  background-color: #feeff3;
  border-color: #feeff3;
  box-shadow:; }
  .btn-pink-100:hover {
    color: #333;
    background-color: #fdd7e1;
    border-color: #fdd7e1; }
  .btn-pink-100:focus, .btn-pink-100.focus {
    color: #333;
    background-color: #fdd7e1;
    border-color: #fdd7e1;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(224, 211, 214, 0.5); }
  .btn-pink-100.disabled, .btn-pink-100:disabled {
    color: #333;
    background-color: #feeff3;
    border-color: #feeff3; }
  .btn-pink-100:not(:disabled):not(.disabled):active, .btn-pink-100:not(:disabled):not(.disabled).active,
  .show > .btn-pink-100 {
    color: #333;
    background-color: #fbbfcf;
    border-color: #fbbfcf; }

.btn-yellow {
  color: #333;
  background-color: #ffd648;
  border-color: #ffd648;
  box-shadow:; }
  .btn-yellow:hover {
    color: #333;
    background-color: #ffd02f;
    border-color: #ffd02f; }
  .btn-yellow:focus, .btn-yellow.focus {
    color: #333;
    background-color: #ffd02f;
    border-color: #ffd02f;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(224, 190, 69, 0.5); }
  .btn-yellow.disabled, .btn-yellow:disabled {
    color: #333;
    background-color: #ffd648;
    border-color: #ffd648; }
  .btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-yellow {
    color: #333;
    background-color: #ffcb15;
    border-color: #ffcb15; }

.btn-yellow-100 {
  color: #333;
  background-color: #fffbed;
  border-color: #fffbed;
  box-shadow:; }
  .btn-yellow-100:hover {
    color: #333;
    background-color: #fff5d4;
    border-color: #fff5d4; }
  .btn-yellow-100:focus, .btn-yellow-100.focus {
    color: #333;
    background-color: #fff5d4;
    border-color: #fff5d4;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(224, 221, 209, 0.5); }
  .btn-yellow-100.disabled, .btn-yellow-100:disabled {
    color: #333;
    background-color: #fffbed;
    border-color: #fffbed; }
  .btn-yellow-100:not(:disabled):not(.disabled):active, .btn-yellow-100:not(:disabled):not(.disabled).active,
  .show > .btn-yellow-100 {
    color: #333;
    background-color: #fff0ba;
    border-color: #fff0ba; }

.btn-outline-primary {
  color: #2196F3;
  border-color: #2196F3; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2196F3;
    border-color: #2196F3; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #2196F3;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary {
    color: #fff;
    background-color: #0c83e2;
    border-color: #0c83e2; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary:focus {
      box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.5); }

.btn-outline-primary-100 {
  color: #e9f5fe;
  border-color: #e9f5fe; }
  .btn-outline-primary-100:hover {
    color: #333;
    background-color: #e9f5fe;
    border-color: #e9f5fe; }
  .btn-outline-primary-100:focus, .btn-outline-primary-100.focus {
    box-shadow: 0 0 0 0 rgba(233, 245, 254, 0.5); }
  .btn-outline-primary-100.disabled, .btn-outline-primary-100:disabled {
    color: #e9f5fe;
    background-color: transparent; }
  .btn-outline-primary-100:not(:disabled):not(.disabled):active, .btn-outline-primary-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-100 {
    color: #333;
    background-color: #c4e4fc;
    border-color: #c4e4fc; }
    .btn-outline-primary-100:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-100:focus {
      box-shadow: 0 0 0 0 rgba(233, 245, 254, 0.5); }

.btn-outline-secondary {
  color: #45748a;
  border-color: #45748a; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #45748a;
    border-color: #45748a; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(69, 116, 138, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #45748a;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary {
    color: #fff;
    background-color: #385f71;
    border-color: #385f71; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary:focus {
      box-shadow: 0 0 0 0 rgba(69, 116, 138, 0.5); }

.btn-outline-secondary-100 {
  color: #ecf1f3;
  border-color: #ecf1f3; }
  .btn-outline-secondary-100:hover {
    color: #333;
    background-color: #ecf1f3;
    border-color: #ecf1f3; }
  .btn-outline-secondary-100:focus, .btn-outline-secondary-100.focus {
    box-shadow: 0 0 0 0 rgba(236, 241, 243, 0.5); }
  .btn-outline-secondary-100.disabled, .btn-outline-secondary-100:disabled {
    color: #ecf1f3;
    background-color: transparent; }
  .btn-outline-secondary-100:not(:disabled):not(.disabled):active, .btn-outline-secondary-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary-100 {
    color: #333;
    background-color: #d5e0e4;
    border-color: #d5e0e4; }
    .btn-outline-secondary-100:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary-100:focus {
      box-shadow: 0 0 0 0 rgba(236, 241, 243, 0.5); }

.btn-outline-success {
  color: #25b372;
  border-color: #25b372; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #25b372;
    border-color: #25b372; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(37, 179, 114, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #25b372;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success {
    color: #fff;
    background-color: #1e935e;
    border-color: #1e935e; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success:focus {
      box-shadow: 0 0 0 0 rgba(37, 179, 114, 0.5); }

.btn-outline-success-100 {
  color: #e9f7f1;
  border-color: #e9f7f1; }
  .btn-outline-success-100:hover {
    color: #333;
    background-color: #e9f7f1;
    border-color: #e9f7f1; }
  .btn-outline-success-100:focus, .btn-outline-success-100.focus {
    box-shadow: 0 0 0 0 rgba(233, 247, 241, 0.5); }
  .btn-outline-success-100.disabled, .btn-outline-success-100:disabled {
    color: #e9f7f1;
    background-color: transparent; }
  .btn-outline-success-100:not(:disabled):not(.disabled):active, .btn-outline-success-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success-100 {
    color: #333;
    background-color: #cdeddf;
    border-color: #cdeddf; }
    .btn-outline-success-100:not(:disabled):not(.disabled):active:focus, .btn-outline-success-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success-100:focus {
      box-shadow: 0 0 0 0 rgba(233, 247, 241, 0.5); }

.btn-outline-info {
  color: #2cbacc;
  border-color: #2cbacc; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #2cbacc;
    border-color: #2cbacc; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(44, 186, 204, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #2cbacc;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info {
    color: #fff;
    background-color: #259dad;
    border-color: #259dad; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info:focus {
      box-shadow: 0 0 0 0 rgba(44, 186, 204, 0.5); }

.btn-outline-info-100 {
  color: #eaf8fa;
  border-color: #eaf8fa; }
  .btn-outline-info-100:hover {
    color: #333;
    background-color: #eaf8fa;
    border-color: #eaf8fa; }
  .btn-outline-info-100:focus, .btn-outline-info-100.focus {
    box-shadow: 0 0 0 0 rgba(234, 248, 250, 0.5); }
  .btn-outline-info-100.disabled, .btn-outline-info-100:disabled {
    color: #eaf8fa;
    background-color: transparent; }
  .btn-outline-info-100:not(:disabled):not(.disabled):active, .btn-outline-info-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info-100 {
    color: #333;
    background-color: #cbeef3;
    border-color: #cbeef3; }
    .btn-outline-info-100:not(:disabled):not(.disabled):active:focus, .btn-outline-info-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info-100:focus {
      box-shadow: 0 0 0 0 rgba(234, 248, 250, 0.5); }

.btn-outline-warning {
  color: #f58646;
  border-color: #f58646; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #f58646;
    border-color: #f58646; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(245, 134, 70, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f58646;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning {
    color: #fff;
    background-color: #f36e22;
    border-color: #f36e22; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning:focus {
      box-shadow: 0 0 0 0 rgba(245, 134, 70, 0.5); }

.btn-outline-warning-100 {
  color: #fef3ed;
  border-color: #fef3ed; }
  .btn-outline-warning-100:hover {
    color: #333;
    background-color: #fef3ed;
    border-color: #fef3ed; }
  .btn-outline-warning-100:focus, .btn-outline-warning-100.focus {
    box-shadow: 0 0 0 0 rgba(254, 243, 237, 0.5); }
  .btn-outline-warning-100.disabled, .btn-outline-warning-100:disabled {
    color: #fef3ed;
    background-color: transparent; }
  .btn-outline-warning-100:not(:disabled):not(.disabled):active, .btn-outline-warning-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning-100 {
    color: #333;
    background-color: #fcdbc9;
    border-color: #fcdbc9; }
    .btn-outline-warning-100:not(:disabled):not(.disabled):active:focus, .btn-outline-warning-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning-100:focus {
      box-shadow: 0 0 0 0 rgba(254, 243, 237, 0.5); }

.btn-outline-danger {
  color: #ef5350;
  border-color: #ef5350; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ef5350;
    border-color: #ef5350; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(239, 83, 80, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ef5350;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger {
    color: #fff;
    background-color: #ec312d;
    border-color: #ec312d; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger:focus {
      box-shadow: 0 0 0 0 rgba(239, 83, 80, 0.5); }

.btn-outline-danger-100 {
  color: #fdeeee;
  border-color: #fdeeee; }
  .btn-outline-danger-100:hover {
    color: #333;
    background-color: #fdeeee;
    border-color: #fdeeee; }
  .btn-outline-danger-100:focus, .btn-outline-danger-100.focus {
    box-shadow: 0 0 0 0 rgba(253, 238, 238, 0.5); }
  .btn-outline-danger-100.disabled, .btn-outline-danger-100:disabled {
    color: #fdeeee;
    background-color: transparent; }
  .btn-outline-danger-100:not(:disabled):not(.disabled):active, .btn-outline-danger-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger-100 {
    color: #333;
    background-color: #f9cccc;
    border-color: #f9cccc; }
    .btn-outline-danger-100:not(:disabled):not(.disabled):active:focus, .btn-outline-danger-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger-100:focus {
      box-shadow: 0 0 0 0 rgba(253, 238, 238, 0.5); }

.btn-outline-white {
  color: #fff;
  border-color: #fff; }
  .btn-outline-white:hover {
    color: #333;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white {
    color: #333;
    background-color: #ececec;
    border-color: #ececec; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white:focus {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-outline-light {
  color: #f0f2f5;
  border-color: #f0f2f5; }
  .btn-outline-light:hover {
    color: #333;
    background-color: #f0f2f5;
    border-color: #f0f2f5; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(240, 242, 245, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f0f2f5;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light {
    color: #333;
    background-color: #d9dee6;
    border-color: #d9dee6; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light:focus {
      box-shadow: 0 0 0 0 rgba(240, 242, 245, 0.5); }

.btn-outline-light-100 {
  color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1); }
  .btn-outline-light-100:hover {
    color: #333;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1); }
  .btn-outline-light-100:focus, .btn-outline-light-100.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-outline-light-100.disabled, .btn-outline-light-100:disabled {
    color: rgba(255, 255, 255, 0.1);
    background-color: transparent; }
  .btn-outline-light-100:not(:disabled):not(.disabled):active, .btn-outline-light-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light-100 {
    color: #333;
    background-color: rgba(236, 236, 236, 0.1);
    border-color: rgba(236, 236, 236, 0.1); }
    .btn-outline-light-100:not(:disabled):not(.disabled):active:focus, .btn-outline-light-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light-100:focus {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-outline-dark {
  color: #252b36;
  border-color: #252b36; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #252b36;
    border-color: #252b36; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(37, 43, 54, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #252b36;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark {
    color: #fff;
    background-color: #15191f;
    border-color: #15191f; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark:focus {
      box-shadow: 0 0 0 0 rgba(37, 43, 54, 0.5); }

.btn-outline-dark-100 {
  color: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1); }
  .btn-outline-dark-100:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1); }
  .btn-outline-dark-100:focus, .btn-outline-dark-100.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }
  .btn-outline-dark-100.disabled, .btn-outline-dark-100:disabled {
    color: rgba(0, 0, 0, 0.1);
    background-color: transparent; }
  .btn-outline-dark-100:not(:disabled):not(.disabled):active, .btn-outline-dark-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark-100 {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1); }
    .btn-outline-dark-100:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark-100:focus {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }

.btn-outline-teal {
  color: #26A69A;
  border-color: #26A69A; }
  .btn-outline-teal:hover {
    color: #fff;
    background-color: #26A69A;
    border-color: #26A69A; }
  .btn-outline-teal:focus, .btn-outline-teal.focus {
    box-shadow: 0 0 0 0 rgba(38, 166, 154, 0.5); }
  .btn-outline-teal.disabled, .btn-outline-teal:disabled {
    color: #26A69A;
    background-color: transparent; }
  .btn-outline-teal:not(:disabled):not(.disabled):active, .btn-outline-teal:not(:disabled):not(.disabled).active,
  .show > .btn-outline-teal {
    color: #fff;
    background-color: #1f877d;
    border-color: #1f877d; }
    .btn-outline-teal:not(:disabled):not(.disabled):active:focus, .btn-outline-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-teal:focus {
      box-shadow: 0 0 0 0 rgba(38, 166, 154, 0.5); }

.btn-outline-teal-100 {
  color: #e9f6f5;
  border-color: #e9f6f5; }
  .btn-outline-teal-100:hover {
    color: #333;
    background-color: #e9f6f5;
    border-color: #e9f6f5; }
  .btn-outline-teal-100:focus, .btn-outline-teal-100.focus {
    box-shadow: 0 0 0 0 rgba(233, 246, 245, 0.5); }
  .btn-outline-teal-100.disabled, .btn-outline-teal-100:disabled {
    color: #e9f6f5;
    background-color: transparent; }
  .btn-outline-teal-100:not(:disabled):not(.disabled):active, .btn-outline-teal-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-teal-100 {
    color: #333;
    background-color: #ceebe9;
    border-color: #ceebe9; }
    .btn-outline-teal-100:not(:disabled):not(.disabled):active:focus, .btn-outline-teal-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-teal-100:focus {
      box-shadow: 0 0 0 0 rgba(233, 246, 245, 0.5); }

.btn-outline-indigo {
  color: #5C6BC0;
  border-color: #5C6BC0; }
  .btn-outline-indigo:hover {
    color: #fff;
    background-color: #5C6BC0;
    border-color: #5C6BC0; }
  .btn-outline-indigo:focus, .btn-outline-indigo.focus {
    box-shadow: 0 0 0 0 rgba(92, 107, 192, 0.5); }
  .btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
    color: #5C6BC0;
    background-color: transparent; }
  .btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-indigo {
    color: #fff;
    background-color: #4555b1;
    border-color: #4555b1; }
    .btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-indigo:focus {
      box-shadow: 0 0 0 0 rgba(92, 107, 192, 0.5); }

.btn-outline-indigo-100 {
  color: #eff0f9;
  border-color: #eff0f9; }
  .btn-outline-indigo-100:hover {
    color: #333;
    background-color: #eff0f9;
    border-color: #eff0f9; }
  .btn-outline-indigo-100:focus, .btn-outline-indigo-100.focus {
    box-shadow: 0 0 0 0 rgba(239, 240, 249, 0.5); }
  .btn-outline-indigo-100.disabled, .btn-outline-indigo-100:disabled {
    color: #eff0f9;
    background-color: transparent; }
  .btn-outline-indigo-100:not(:disabled):not(.disabled):active, .btn-outline-indigo-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-indigo-100 {
    color: #333;
    background-color: #d3d6ef;
    border-color: #d3d6ef; }
    .btn-outline-indigo-100:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-indigo-100:focus {
      box-shadow: 0 0 0 0 rgba(239, 240, 249, 0.5); }

.btn-outline-purple {
  color: #8e70c1;
  border-color: #8e70c1; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #8e70c1;
    border-color: #8e70c1; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0 rgba(142, 112, 193, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #8e70c1;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple {
    color: #fff;
    background-color: #7955b5;
    border-color: #7955b5; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple:focus {
      box-shadow: 0 0 0 0 rgba(142, 112, 193, 0.5); }

.btn-outline-purple-100 {
  color: #f4f1f9;
  border-color: #f4f1f9; }
  .btn-outline-purple-100:hover {
    color: #333;
    background-color: #f4f1f9;
    border-color: #f4f1f9; }
  .btn-outline-purple-100:focus, .btn-outline-purple-100.focus {
    box-shadow: 0 0 0 0 rgba(244, 241, 249, 0.5); }
  .btn-outline-purple-100.disabled, .btn-outline-purple-100:disabled {
    color: #f4f1f9;
    background-color: transparent; }
  .btn-outline-purple-100:not(:disabled):not(.disabled):active, .btn-outline-purple-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple-100 {
    color: #333;
    background-color: #dfd6ee;
    border-color: #dfd6ee; }
    .btn-outline-purple-100:not(:disabled):not(.disabled):active:focus, .btn-outline-purple-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple-100:focus {
      box-shadow: 0 0 0 0 rgba(244, 241, 249, 0.5); }

.btn-outline-pink {
  color: #f35c86;
  border-color: #f35c86; }
  .btn-outline-pink:hover {
    color: #fff;
    background-color: #f35c86;
    border-color: #f35c86; }
  .btn-outline-pink:focus, .btn-outline-pink.focus {
    box-shadow: 0 0 0 0 rgba(243, 92, 134, 0.5); }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    color: #f35c86;
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink {
    color: #fff;
    background-color: #f0386c;
    border-color: #f0386c; }
    .btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink:focus {
      box-shadow: 0 0 0 0 rgba(243, 92, 134, 0.5); }

.btn-outline-pink-100 {
  color: #feeff3;
  border-color: #feeff3; }
  .btn-outline-pink-100:hover {
    color: #333;
    background-color: #feeff3;
    border-color: #feeff3; }
  .btn-outline-pink-100:focus, .btn-outline-pink-100.focus {
    box-shadow: 0 0 0 0 rgba(254, 239, 243, 0.5); }
  .btn-outline-pink-100.disabled, .btn-outline-pink-100:disabled {
    color: #feeff3;
    background-color: transparent; }
  .btn-outline-pink-100:not(:disabled):not(.disabled):active, .btn-outline-pink-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink-100 {
    color: #333;
    background-color: #fccbd8;
    border-color: #fccbd8; }
    .btn-outline-pink-100:not(:disabled):not(.disabled):active:focus, .btn-outline-pink-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink-100:focus {
      box-shadow: 0 0 0 0 rgba(254, 239, 243, 0.5); }

.btn-outline-yellow {
  color: #ffd648;
  border-color: #ffd648; }
  .btn-outline-yellow:hover {
    color: #333;
    background-color: #ffd648;
    border-color: #ffd648; }
  .btn-outline-yellow:focus, .btn-outline-yellow.focus {
    box-shadow: 0 0 0 0 rgba(255, 214, 72, 0.5); }
  .btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
    color: #ffd648;
    background-color: transparent; }
  .btn-outline-yellow:not(:disabled):not(.disabled):active, .btn-outline-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-yellow {
    color: #333;
    background-color: #ffcd22;
    border-color: #ffcd22; }
    .btn-outline-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-yellow:focus {
      box-shadow: 0 0 0 0 rgba(255, 214, 72, 0.5); }

.btn-outline-yellow-100 {
  color: #fffbed;
  border-color: #fffbed; }
  .btn-outline-yellow-100:hover {
    color: #333;
    background-color: #fffbed;
    border-color: #fffbed; }
  .btn-outline-yellow-100:focus, .btn-outline-yellow-100.focus {
    box-shadow: 0 0 0 0 rgba(255, 251, 237, 0.5); }
  .btn-outline-yellow-100.disabled, .btn-outline-yellow-100:disabled {
    color: #fffbed;
    background-color: transparent; }
  .btn-outline-yellow-100:not(:disabled):not(.disabled):active, .btn-outline-yellow-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-yellow-100 {
    color: #333;
    background-color: #fff3c7;
    border-color: #fff3c7; }
    .btn-outline-yellow-100:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-yellow-100:focus {
      box-shadow: 0 0 0 0 rgba(255, 251, 237, 0.5); }

.btn-link {
  font-weight: 400;
  color: #2196F3;
  text-decoration: none; }
  .btn-link:hover {
    color: #0a6ebd;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #999;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5625rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.4667;
  border-radius: 0.3125rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.3125rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.6924;
  border-radius: 0.1875rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    font-family: "icomoon";
    display: inline-block;
    border: 0;
    vertical-align: middle;
    font-size: 1rem;
    margin-left: 0.46875rem;
    line-height: 1;
    position: relative;
    content: ""; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  /*rtl:ignore*/
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 12.5rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #333;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 769px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  font-family: "icomoon";
  display: inline-block;
  border: 0;
  vertical-align: middle;
  font-size: 1rem;
  margin-left: 0.46875rem;
  line-height: 1;
  position: relative;
  content: ""; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  /*rtl:begin:ignore*/
  right: auto;
  left: 100%;
  /*rtl:end:ignore*/
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  font-family: "icomoon";
  display: inline-block;
  border: 0;
  vertical-align: middle;
  font-size: 1rem;
  margin-left: 0.46875rem;
  line-height: 1;
  position: relative;
  content: ""; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  font-family: "icomoon";
  display: inline-block;
  border: 0;
  vertical-align: middle;
  font-size: 1rem;
  margin-left: 0.46875rem;
  line-height: 1;
  position: relative; }

.dropleft .dropdown-toggle::after {
  content: none; }

.dropleft .dropdown-toggle::before {
  font-family: "icomoon";
  display: inline-block;
  border: 0;
  font-size: 1rem;
  margin-right: 0.46875rem;
  line-height: 1;
  position: relative;
  content: ""; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #ddd; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #333;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #333;
    text-decoration: none;
    background-color: #eee; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #2196F3; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #999;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  color: #999;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1rem;
  color: #333; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.65625rem;
  padding-left: 0.65625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow:; }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: stretch;
      align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.4375rem 0.875rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5715;
  color: #333;
  text-align: center;
  white-space: nowrap;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.4667em + 1.125rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5625rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.4667;
  border-radius: 0.3125rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.6924em + 0.625rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.3125rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.6924;
  border-radius: 0.1875rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.1875rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.37506rem;
  padding-left: 2rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact; }

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.25rem;
  height: 1.31253rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2196F3;
    background-color: #2196F3;
    box-shadow:; }
  .custom-control-input:focus ~ .custom-control-label::before { }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #c4c4c4; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    box-shadow:; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #999; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #fff; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  cursor: pointer; }
  .custom-control-label::before {
    position: absolute;
    top: 0.06253rem;
    left: -2rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #ddd solid 2px;
    box-shadow: 0 0 0 0 transparent; }
  .custom-control-label::after {
    position: absolute;
    top: 0.06253rem;
    left: -2rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.1875rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='%23fff' d='M13.5 2l-7.5 7.5-3.5-3.5-2.5 2.5 6 6 10-10z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ddd;
  background-color: #fff;
  box-shadow:; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #2196F3; }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: #2196F3; }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #2196F3; }

.custom-switch {
  padding-left: 2.9375rem; }
  .custom-switch .custom-control-label::before {
    left: -2.9375rem;
    width: 2.1875rem;
    pointer-events: all;
    border-radius: 0.625rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.06253rem + 4px);
    left: calc(-2.9375rem + 4px);
    width: 1rem;
    height: 1rem;
    background-color: #ddd;
    border-radius: 0.625rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.9375rem);
            transform: translateX(0.9375rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #2196F3; }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5715em + 0.875rem + 2px);
  padding: 0.4375rem 2.1875rem 0.4375rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5715;
  color: #333;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.875rem center/8px 10px no-repeat;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-shadow: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #2196F3;
    outline: 0; }
    .custom-select:focus::-ms-value {
      color: #333;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.875rem;
    background-image: none; }
  .custom-select:disabled {
    color: #999;
    background-color: #f5f5f5; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #333; }

.custom-select-sm {
  height: calc(1.6924em + 0.625rem + 2px);
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  padding-left: 0.75rem;
  font-size: 0.8125rem; }

.custom-select-lg {
  height: calc(1.4667em + 1.125rem + 2px);
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  padding-left: 1rem;
  font-size: 0.9375rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5715em + 0.875rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5715em + 0.875rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #2196F3; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #fafafa; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5715em + 0.875rem + 2px);
  padding: 0.4375rem 0.875rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5715;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-shadow:; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5715em + 0.875rem);
    padding: 0.4375rem 0.875rem;
    line-height: 1.5715;
    color: #333;
    content: "Browse";
    background-color: #f0f2f5;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #f5f5f5; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #f5f5f5; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #f5f5f5; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2196F3;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #cae6fc; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #eee;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2196F3;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #cae6fc; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #eee;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background-color: #2196F3;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #cae6fc; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-fill-lower {
    background-color: #eee;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #eee;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #999; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #999; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #999; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.75rem 1.25rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #999;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: transparent; }
    .nav-tabs .nav-link.disabled {
      color: #999;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #333;
    background-color: #fff;
    border-color: #ddd #ddd #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2196F3; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0 1.25rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  margin-right: 1.25rem;
  font-size: 0;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-align: center;
      align-items: center; }

.navbar-toggler {
  padding: 1rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 768.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 769px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 1.25rem;
      padding-left: 1.25rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: #333; }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: #333; }

.navbar-light .navbar-nav .nav-link {
  color: #333; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #333; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.65); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #333; }

.navbar-light .navbar-toggler {
  color: #333;
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23333' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: #333; }
  .navbar-light .navbar-text a {
    color: #333; }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: #333; }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: #fff; }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff; }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.65); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: #fff; }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 1.25rem; }

.card-subtitle {
  margin-top: -0.625rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 1.25rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 1.25rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.02);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -1.25rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 1.25rem; }

@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    margin-right: -1.25rem;
    margin-left: -1.25rem; }
    .card-deck .card {
      -ms-flex: 1 0 0%;
          flex: 1 0 0%;
      margin-right: 1.25rem;
      margin-bottom: 0;
      margin-left: 1.25rem; } }

.card-group > .card {
  margin-bottom: 1.25rem; }

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap; }
    .card-group > .card {
      -ms-flex: 1 0 0%;
          flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 1.25rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.625rem 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.625rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.625rem;
    color: inherit;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #999; }

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.4375rem 0.875rem;
  margin-left: -1px;
  line-height: 1.5715;
  color: #333;
  background-color: #f0f2f5;
  border: 1px solid #cad1dc; }
  .page-link:hover {
    z-index: 2;
    color: #333;
    text-decoration: none;
    background-color: #e1e5eb;
    border-color: #bac4d1; }
  .page-link:focus {
    z-index: 3;
    outline: 0; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2196F3;
  border-color: #2196F3; }

.page-item.disabled .page-link {
  color: #ccc;
  pointer-events: none;
  cursor: auto;
  background-color: #f0f2f5;
  border-color: #cad1dc; }

.pagination-lg .page-link {
  padding: 0.5625rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.4667; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem; }

.pagination-sm .page-link {
  padding: 0.3125rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.6924; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem; }

.badge {
  display: inline-block;
  padding: 0.3125rem 0.4375rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #2196F3; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0c7cd5; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.5); }

.badge-primary-100 {
  color: #333;
  background-color: #e9f5fe; }
  a.badge-primary-100:hover, a.badge-primary-100:focus {
    color: #333;
    background-color: #b8dffc; }
  a.badge-primary-100:focus, a.badge-primary-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(233, 245, 254, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #45748a; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #345768; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(69, 116, 138, 0.5); }

.badge-secondary-100 {
  color: #333;
  background-color: #ecf1f3; }
  a.badge-secondary-100:hover, a.badge-secondary-100:focus {
    color: #333;
    background-color: #cddadf; }
  a.badge-secondary-100:focus, a.badge-secondary-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(236, 241, 243, 0.5); }

.badge-success {
  color: #fff;
  background-color: #25b372; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1c8957; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(37, 179, 114, 0.5); }

.badge-success-100 {
  color: #333;
  background-color: #e9f7f1; }
  a.badge-success-100:hover, a.badge-success-100:focus {
    color: #333;
    background-color: #c4e9d9; }
  a.badge-success-100:focus, a.badge-success-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(233, 247, 241, 0.5); }

.badge-info {
  color: #fff;
  background-color: #2cbacc; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #2394a2; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(44, 186, 204, 0.5); }

.badge-info-100 {
  color: #333;
  background-color: #eaf8fa; }
  a.badge-info-100:hover, a.badge-info-100:focus {
    color: #333;
    background-color: #c1eaf0; }
  a.badge-info-100:focus, a.badge-info-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(234, 248, 250, 0.5); }

.badge-warning {
  color: #fff;
  background-color: #f58646; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #fff;
    background-color: #f26616; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(245, 134, 70, 0.5); }

.badge-warning-100 {
  color: #333;
  background-color: #fef3ed; }
  a.badge-warning-100:hover, a.badge-warning-100:focus {
    color: #333;
    background-color: #fbd3bd; }
  a.badge-warning-100:focus, a.badge-warning-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(254, 243, 237, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #ef5350; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #eb2521; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(239, 83, 80, 0.5); }

.badge-danger-100 {
  color: #333;
  background-color: #fdeeee; }
  a.badge-danger-100:hover, a.badge-danger-100:focus {
    color: #333;
    background-color: #f8c0c0; }
  a.badge-danger-100:focus, a.badge-danger-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(253, 238, 238, 0.5); }

.badge-white {
  color: #333;
  background-color: #fff; }
  a.badge-white:hover, a.badge-white:focus {
    color: #333;
    background-color: #e6e6e6; }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.badge-light {
  color: #333;
  background-color: #f0f2f5; }
  a.badge-light:hover, a.badge-light:focus {
    color: #333;
    background-color: #d1d7e1; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(240, 242, 245, 0.5); }

.badge-light-100 {
  color: #333;
  background-color: rgba(255, 255, 255, 0.1); }
  a.badge-light-100:hover, a.badge-light-100:focus {
    color: #333;
    background-color: rgba(230, 230, 230, 0.1); }
  a.badge-light-100:focus, a.badge-light-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #252b36; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #101318; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(37, 43, 54, 0.5); }

.badge-dark-100 {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1); }
  a.badge-dark-100:hover, a.badge-dark-100:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1); }
  a.badge-dark-100:focus, a.badge-dark-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }

.badge-teal {
  color: #fff;
  background-color: #26A69A; }
  a.badge-teal:hover, a.badge-teal:focus {
    color: #fff;
    background-color: #1d7d74; }
  a.badge-teal:focus, a.badge-teal.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(38, 166, 154, 0.5); }

.badge-teal-100 {
  color: #333;
  background-color: #e9f6f5; }
  a.badge-teal-100:hover, a.badge-teal-100:focus {
    color: #333;
    background-color: #c5e7e5; }
  a.badge-teal-100:focus, a.badge-teal-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(233, 246, 245, 0.5); }

.badge-indigo {
  color: #fff;
  background-color: #5C6BC0; }
  a.badge-indigo:hover, a.badge-indigo:focus {
    color: #fff;
    background-color: #4150a8; }
  a.badge-indigo:focus, a.badge-indigo.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(92, 107, 192, 0.5); }

.badge-indigo-100 {
  color: #333;
  background-color: #eff0f9; }
  a.badge-indigo-100:hover, a.badge-indigo-100:focus {
    color: #333;
    background-color: #cacdeb; }
  a.badge-indigo-100:focus, a.badge-indigo-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(239, 240, 249, 0.5); }

.badge-purple {
  color: #fff;
  background-color: #8e70c1; }
  a.badge-purple:hover, a.badge-purple:focus {
    color: #fff;
    background-color: #724db1; }
  a.badge-purple:focus, a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(142, 112, 193, 0.5); }

.badge-purple-100 {
  color: #333;
  background-color: #f4f1f9; }
  a.badge-purple-100:hover, a.badge-purple-100:focus {
    color: #333;
    background-color: #d8cdea; }
  a.badge-purple-100:focus, a.badge-purple-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(244, 241, 249, 0.5); }

.badge-pink {
  color: #fff;
  background-color: #f35c86; }
  a.badge-pink:hover, a.badge-pink:focus {
    color: #fff;
    background-color: #f02c63; }
  a.badge-pink:focus, a.badge-pink.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(243, 92, 134, 0.5); }

.badge-pink-100 {
  color: #333;
  background-color: #feeff3; }
  a.badge-pink-100:hover, a.badge-pink-100:focus {
    color: #333;
    background-color: #fbbfcf; }
  a.badge-pink-100:focus, a.badge-pink-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(254, 239, 243, 0.5); }

.badge-yellow {
  color: #333;
  background-color: #ffd648; }
  a.badge-yellow:hover, a.badge-yellow:focus {
    color: #333;
    background-color: #ffcb15; }
  a.badge-yellow:focus, a.badge-yellow.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 214, 72, 0.5); }

.badge-yellow-100 {
  color: #333;
  background-color: #fffbed; }
  a.badge-yellow-100:hover, a.badge-yellow-100:focus {
    color: #333;
    background-color: #fff0ba; }
  a.badge-yellow-100:focus, a.badge-yellow-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 251, 237, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f5f5f5;
  border-radius: 0.3125rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 500; }

.alert-dismissible {
  padding-right: 3.87506rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #114e7e;
  background-color: #dbeefd;
  border-color: #339ef4; }
  .alert-primary hr {
    border-top-color: #1b92f3; }
  .alert-primary .alert-link {
    color: #0b3251; }

.alert-primary-100 {
  color: #797f84;
  background-color: #fbfdff;
  border-color: #ebf6fe; }
  .alert-primary-100 hr {
    border-top-color: #d3ebfd; }
  .alert-primary-100 .alert-link {
    color: #616569; }

.alert-secondary {
  color: #243c48;
  background-color: #e1e9ec;
  border-color: #547f93; }
  .alert-secondary hr {
    border-top-color: #4b7183; }
  .alert-secondary .alert-link {
    color: #132026; }

.alert-secondary-100 {
  color: #7b7d7e;
  background-color: #fcfdfd;
  border-color: #eef2f4; }
  .alert-secondary-100 hr {
    border-top-color: #dfe6ea; }
  .alert-secondary-100 .alert-link {
    color: #626364; }

.alert-success {
  color: #135d3b;
  background-color: #dcf3e8;
  border-color: #36b97d; }
  .alert-success hr {
    border-top-color: #30a570; }
  .alert-success .alert-link {
    color: #0a3320; }

.alert-success-100 {
  color: #79807d;
  background-color: #fbfefd;
  border-color: #ebf8f2; }
  .alert-success-100 hr {
    border-top-color: #d8f1e6; }
  .alert-success-100 .alert-link {
    color: #606663; }

.alert-info {
  color: #17616a;
  background-color: #ddf4f7;
  border-color: #3dc0d0; }
  .alert-info hr {
    border-top-color: #2fb4c4; }
  .alert-info .alert-link {
    color: #0e3b40; }

.alert-info-100 {
  color: #7a8182;
  background-color: #fcfefe;
  border-color: #ecf9fa; }
  .alert-info-100 hr {
    border-top-color: #d8f3f5; }
  .alert-info-100 .alert-link {
    color: #616768; }

.alert-warning {
  color: #7f4624;
  background-color: #fdece1;
  border-color: #f69055; }
  .alert-warning hr {
    border-top-color: #f5803d; }
  .alert-warning .alert-link {
    color: #573019; }

.alert-warning-100 {
  color: #847e7b;
  background-color: #fffdfc;
  border-color: #fef4ee; }
  .alert-warning-100 hr {
    border-top-color: #fde4d6; }
  .alert-warning-100 .alert-link {
    color: #6a6562; }

.alert-danger {
  color: #7c2b2a;
  background-color: #fce3e3;
  border-color: #f0615e; }
  .alert-danger hr {
    border-top-color: #ee4a47; }
  .alert-danger .alert-link {
    color: #561e1d; }

.alert-danger-100 {
  color: #847c7c;
  background-color: #fffcfc;
  border-color: #fdefef; }
  .alert-danger-100 hr {
    border-top-color: #fad8d8; }
  .alert-danger-100 .alert-link {
    color: #6a6363; }

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #6c6c6c; }

.alert-light {
  color: #7d7e7f;
  background-color: #fdfdfd;
  border-color: #f1f3f6; }
  .alert-light hr {
    border-top-color: #e1e6ec; }
  .alert-light .alert-link {
    color: #646565; }

.alert-light-100 {
  color: rgba(14, 14, 14, 0.532);
  background-color: rgba(255, 255, 255, 0.856);
  border-color: rgba(255, 255, 255, 0.172); }
  .alert-light-100 hr {
    border-top-color: rgba(242, 242, 242, 0.172); }
  .alert-light-100 .alert-link {
    color: rgba(0, 0, 0, 0.532); }

.alert-dark {
  color: #13161c;
  background-color: #dcdddf;
  border-color: #363c46; }
  .alert-dark hr {
    border-top-color: #2b3038; }
  .alert-dark .alert-link {
    color: black; }

.alert-dark-100 {
  color: rgba(0, 0, 0, 0.532);
  background-color: rgba(252, 252, 252, 0.856);
  border-color: rgba(159, 159, 159, 0.172); }
  .alert-dark-100 hr {
    border-top-color: rgba(146, 146, 146, 0.172); }
  .alert-dark-100 .alert-link {
    color: rgba(0, 0, 0, 0.532); }

.alert-teal {
  color: #145650;
  background-color: #dcf1ef;
  border-color: #37ada2; }
  .alert-teal hr {
    border-top-color: #319a90; }
  .alert-teal .alert-link {
    color: #0a2d2a; }

.alert-teal-100 {
  color: #79807f;
  background-color: #fbfefd;
  border-color: #ebf7f6; }
  .alert-teal-100 hr {
    border-top-color: #d9f0ee; }
  .alert-teal-100 .alert-link {
    color: #606665; }

.alert-indigo {
  color: #303864;
  background-color: #e5e7f5;
  border-color: #6977c5; }
  .alert-indigo hr {
    border-top-color: #5766be; }
  .alert-indigo .alert-link {
    color: #1f2542; }

.alert-indigo-100 {
  color: #7c7d81;
  background-color: #fcfdfe;
  border-color: #f0f1f9; }
  .alert-indigo-100 hr {
    border-top-color: #dee0f2; }
  .alert-indigo-100 .alert-link {
    color: #636467; }

.alert-purple {
  color: #4a3a64;
  background-color: #ede8f5;
  border-color: #977bc6; }
  .alert-purple hr {
    border-top-color: #8969be; }
  .alert-purple .alert-link {
    color: #322744; }

.alert-purple-100 {
  color: #7f7d81;
  background-color: #fdfdfe;
  border-color: #f5f2f9; }
  .alert-purple-100 hr {
    border-top-color: #e8e1f1; }
  .alert-purple-100 .alert-link {
    color: #666467; }

.alert-pink {
  color: #7e3046;
  background-color: #fde5ec;
  border-color: #f46990; }
  .alert-pink hr {
    border-top-color: #f2517e; }
  .alert-pink .alert-link {
    color: #592231; }

.alert-pink-100 {
  color: #847c7e;
  background-color: #fffcfd;
  border-color: #fef0f4; }
  .alert-pink-100 hr {
    border-top-color: #fcd8e2; }
  .alert-pink-100 .alert-link {
    color: #6a6365; }

.alert-yellow {
  color: #856f25;
  background-color: #fff8e2;
  border-color: #ffd957; }
  .alert-yellow hr {
    border-top-color: #ffd33e; }
  .alert-yellow .alert-link {
    color: #5d4e1a; }

.alert-yellow-100 {
  color: #85837b;
  background-color: #fffefc;
  border-color: #fffbee; }
  .alert-yellow-100 hr {
    border-top-color: #fff5d5; }
  .alert-yellow-100 .alert-link {
    color: #6b6962; }

@-webkit-keyframes progress-bar-stripes {
  /*rtl:begin:ignore*/
  from {
    background-position: 1.25rem 0; }
  to {
    background-position: 0 0; }
  /*rtl:end:ignore*/ }

@keyframes progress-bar-stripes {
  /*rtl:begin:ignore*/
  from {
    background-position: 1.25rem 0; }
  to {
    background-position: 0 0; }
  /*rtl:end:ignore*/ }

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1.25rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #eee;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.1); }

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2196F3;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1.25rem 1.25rem; }

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start; }

.media-body {
  -ms-flex: 1;
      flex: 1; }

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: rgba(51, 51, 51, 0.85);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #333;
    text-decoration: none;
    background-color: #f5f5f5; }
  .list-group-item-action:active {
    color: #333;
    background-color: #f5f5f5; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.625rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #999;
    pointer-events: none;
    background-color: transparent; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2196F3;
    border-color: #2196F3; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  -ms-flex-direction: row;
      flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
        flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 769px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
        flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
        flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
        flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #114e7e;
  background-color: #c1e2fc; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #114e7e;
    background-color: #a9d7fb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #114e7e;
    border-color: #114e7e; }

.list-group-item-primary-100 {
  color: #797f84;
  background-color: #f9fcff; }
  .list-group-item-primary-100.list-group-item-action:hover, .list-group-item-primary-100.list-group-item-action:focus {
    color: #797f84;
    background-color: #e0efff; }
  .list-group-item-primary-100.list-group-item-action.active {
    color: #fff;
    background-color: #797f84;
    border-color: #797f84; }

.list-group-item-secondary {
  color: #243c48;
  background-color: #cbd8de; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #243c48;
    background-color: #bbccd4; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #243c48;
    border-color: #243c48; }

.list-group-item-secondary-100 {
  color: #7b7d7e;
  background-color: #fafbfc; }
  .list-group-item-secondary-100.list-group-item-action:hover, .list-group-item-secondary-100.list-group-item-action:focus {
    color: #7b7d7e;
    background-color: #eaeef2; }
  .list-group-item-secondary-100.list-group-item-action.active {
    color: #fff;
    background-color: #7b7d7e;
    border-color: #7b7d7e; }

.list-group-item-success {
  color: #135d3b;
  background-color: #c2ead8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #135d3b;
    background-color: #afe3cc; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #135d3b;
    border-color: #135d3b; }

.list-group-item-success-100 {
  color: #79807d;
  background-color: #f9fdfb; }
  .list-group-item-success-100.list-group-item-action:hover, .list-group-item-success-100.list-group-item-action:focus {
    color: #79807d;
    background-color: #e6f7ee; }
  .list-group-item-success-100.list-group-item-action.active {
    color: #fff;
    background-color: #79807d;
    border-color: #79807d; }

.list-group-item-info {
  color: #17616a;
  background-color: #c4ecf1; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #17616a;
    background-color: #afe5ec; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #17616a;
    border-color: #17616a; }

.list-group-item-info-100 {
  color: #7a8182;
  background-color: #f9fdfe; }
  .list-group-item-info-100.list-group-item-action:hover, .list-group-item-info-100.list-group-item-action:focus {
    color: #7a8182;
    background-color: #e3f6fa; }
  .list-group-item-info-100.list-group-item-action.active {
    color: #fff;
    background-color: #7a8182;
    border-color: #7a8182; }

.list-group-item-warning {
  color: #7f4624;
  background-color: #fcddcb; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7f4624;
    background-color: #fbcdb3; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7f4624;
    border-color: #7f4624; }

.list-group-item-warning-100 {
  color: #847e7b;
  background-color: #fffcfa; }
  .list-group-item-warning-100.list-group-item-action:hover, .list-group-item-warning-100.list-group-item-action:focus {
    color: #847e7b;
    background-color: #ffede1; }
  .list-group-item-warning-100.list-group-item-action.active {
    color: #fff;
    background-color: #847e7b;
    border-color: #847e7b; }

.list-group-item-danger {
  color: #7c2b2a;
  background-color: #fbcfce; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7c2b2a;
    background-color: #f9b8b6; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7c2b2a;
    border-color: #7c2b2a; }

.list-group-item-danger-100 {
  color: #847c7c;
  background-color: #fefafa; }
  .list-group-item-danger-100.list-group-item-action:hover, .list-group-item-danger-100.list-group-item-action:focus {
    color: #847c7c;
    background-color: #fae5e5; }
  .list-group-item-danger-100.list-group-item-action.active {
    color: #fff;
    background-color: #847c7c;
    border-color: #847c7c; }

.list-group-item-white {
  color: #858585;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-light {
  color: #7d7e7f;
  background-color: #fbfbfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7d7e7f;
    background-color: #ececf1; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7d7e7f;
    border-color: #7d7e7f; }

.list-group-item-light-100 {
  color: rgba(14, 14, 14, 0.532);
  background-color: rgba(255, 255, 255, 0.748); }
  .list-group-item-light-100.list-group-item-action:hover, .list-group-item-light-100.list-group-item-action:focus {
    color: rgba(14, 14, 14, 0.532);
    background-color: rgba(242, 242, 242, 0.748); }
  .list-group-item-light-100.list-group-item-action.active {
    color: #fff;
    background-color: rgba(14, 14, 14, 0.532);
    border-color: rgba(14, 14, 14, 0.532); }

.list-group-item-dark {
  color: #13161c;
  background-color: #c2c4c7; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #13161c;
    background-color: #b5b7bb; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #13161c;
    border-color: #13161c; }

.list-group-item-dark-100 {
  color: rgba(0, 0, 0, 0.532);
  background-color: rgba(250, 250, 250, 0.748); }
  .list-group-item-dark-100.list-group-item-action:hover, .list-group-item-dark-100.list-group-item-action:focus {
    color: rgba(0, 0, 0, 0.532);
    background-color: rgba(237, 237, 237, 0.748); }
  .list-group-item-dark-100.list-group-item-action.active {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.532);
    border-color: rgba(0, 0, 0, 0.532); }

.list-group-item-teal {
  color: #145650;
  background-color: #c2e6e3; }
  .list-group-item-teal.list-group-item-action:hover, .list-group-item-teal.list-group-item-action:focus {
    color: #145650;
    background-color: #b0dfdb; }
  .list-group-item-teal.list-group-item-action.active {
    color: #fff;
    background-color: #145650;
    border-color: #145650; }

.list-group-item-teal-100 {
  color: #79807f;
  background-color: #f9fcfc; }
  .list-group-item-teal-100.list-group-item-action:hover, .list-group-item-teal-100.list-group-item-action:focus {
    color: #79807f;
    background-color: #e8f4f4; }
  .list-group-item-teal-100.list-group-item-action.active {
    color: #fff;
    background-color: #79807f;
    border-color: #79807f; }

.list-group-item-indigo {
  color: #303864;
  background-color: #d1d6ed; }
  .list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
    color: #303864;
    background-color: #bfc6e6; }
  .list-group-item-indigo.list-group-item-action.active {
    color: #fff;
    background-color: #303864;
    border-color: #303864; }

.list-group-item-indigo-100 {
  color: #7c7d81;
  background-color: #fbfbfd; }
  .list-group-item-indigo-100.list-group-item-action:hover, .list-group-item-indigo-100.list-group-item-action:focus {
    color: #7c7d81;
    background-color: #eaeaf5; }
  .list-group-item-indigo-100.list-group-item-action.active {
    color: #fff;
    background-color: #7c7d81;
    border-color: #7c7d81; }

.list-group-item-purple {
  color: #4a3a64;
  background-color: #dfd7ee; }
  .list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
    color: #4a3a64;
    background-color: #d1c5e6; }
  .list-group-item-purple.list-group-item-action.active {
    color: #fff;
    background-color: #4a3a64;
    border-color: #4a3a64; }

.list-group-item-purple-100 {
  color: #7f7d81;
  background-color: #fcfbfd; }
  .list-group-item-purple-100.list-group-item-action:hover, .list-group-item-purple-100.list-group-item-action:focus {
    color: #7f7d81;
    background-color: #efeaf5; }
  .list-group-item-purple-100.list-group-item-action.active {
    color: #fff;
    background-color: #7f7d81;
    border-color: #7f7d81; }

.list-group-item-pink {
  color: #7e3046;
  background-color: #fcd1dd; }
  .list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
    color: #7e3046;
    background-color: #fab9cb; }
  .list-group-item-pink.list-group-item-action.active {
    color: #fff;
    background-color: #7e3046;
    border-color: #7e3046; }

.list-group-item-pink-100 {
  color: #847c7e;
  background-color: #fffbfc; }
  .list-group-item-pink-100.list-group-item-action:hover, .list-group-item-pink-100.list-group-item-action:focus {
    color: #847c7e;
    background-color: #ffe2e9; }
  .list-group-item-pink-100.list-group-item-action.active {
    color: #fff;
    background-color: #847c7e;
    border-color: #847c7e; }

.list-group-item-yellow {
  color: #856f25;
  background-color: #fff4cc; }
  .list-group-item-yellow.list-group-item-action:hover, .list-group-item-yellow.list-group-item-action:focus {
    color: #856f25;
    background-color: #ffefb3; }
  .list-group-item-yellow.list-group-item-action.active {
    color: #fff;
    background-color: #856f25;
    border-color: #856f25; }

.list-group-item-yellow-100 {
  color: #85837b;
  background-color: #fffefa; }
  .list-group-item-yellow-100.list-group-item-action:hover, .list-group-item-yellow-100.list-group-item-action:focus {
    color: #85837b;
    background-color: #fff9e1; }
  .list-group-item-yellow-100.list-group-item-action.active {
    color: #fff;
    background-color: #85837b;
    border-color: #85837b; }

.close {
  float: right;
  font-size: 1.37506rem;
  font-weight: 400;
  line-height: 1;
  color: #333;
  text-shadow: none;
  opacity: .5; }
  .close:hover {
    color: #333;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  -ms-flex-preferred-size: 350px;
      flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  color: #fff;
  background-color: #333;
  background-clip: padding-box;
  border: 1px solid transparent;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  opacity: 0;
  border-radius: 0.3125rem; }
  .toast:not(:last-child) {
    margin-bottom: 1.25rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.75rem 1.25rem;
  color: #fff;
  background-color: #333;
  background-clip: padding-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top-left-radius: calc(0.3125rem - 1px);
  border-top-right-radius: calc(0.3125rem - 1px); }

.toast-body {
  padding: 1.25rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.35; }

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 1.25rem 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: calc(0.3125rem - 1px);
  border-top-right-radius: calc(0.3125rem - 1px); }
  .modal-header .close {
    padding: 1.25rem 1.25rem;
    margin: -1.25rem -1.25rem -1.25rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5715; }

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1.25rem; }

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-right-radius: calc(0.3125rem - 1px);
  border-bottom-left-radius: calc(0.3125rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content; }
  .modal-content {
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); }
  .modal-sm {
    max-width: 400px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 900px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0.3125rem;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5715;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.5rem;
    height: 0.25rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.25rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.25rem 0.25rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.25rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    /*rtl:ignore*/
    left: 0;
    width: 0.25rem;
    height: 0.5rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      /*rtl:begin:ignore*/
      right: 0;
      border-width: 0.25rem 0.25rem 0.25rem 0;
      border-right-color: #000;
      /*rtl:end:ignore*/ }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.25rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.25rem 0.25rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.25rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    /*rtl:ignore*/
    right: 0;
    width: 0.25rem;
    height: 0.5rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      /*rtl:begin:ignore*/
      left: 0;
      border-width: 0.25rem 0 0.25rem 0.25rem;
      border-left-color: #000;
      /*rtl:end:ignore*/ }

.tooltip-inner {
  max-width: 200px;
  padding: 0.375rem 0.75rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5715;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 0.75rem;
    height: 0.375rem;
    margin: 0 0.25rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.375rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.375rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.375rem 0.375rem 0;
      border-top-color: rgba(0, 0, 0, 0.2); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.375rem 0.375rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  /*rtl:ignore*/
  margin-left: 0.375rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    /*rtl:ignore*/
    left: calc(-0.375rem - 1px);
    width: 0.375rem;
    height: 0.75rem;
    margin: 0.25rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      /*rtl:begin:ignore*/
      left: 0;
      border-width: 0.375rem 0.375rem 0.375rem 0;
      border-right-color: rgba(0, 0, 0, 0.2);
      /*rtl:end:ignore*/ }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      /*rtl:begin:ignore*/
      left: 1px;
      border-width: 0.375rem 0.375rem 0.375rem 0;
      border-right-color: #fff;
      /*rtl:end:ignore*/ }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.375rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.375rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.375rem 0.375rem 0.375rem;
      border-bottom-color: rgba(0, 0, 0, 0.2); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.375rem 0.375rem 0.375rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 0.75rem;
    margin-left: -0.375rem;
    content: "";
    border-bottom: 1px solid transparent; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  /*rtl:ignore*/
  margin-right: 0.375rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    /*rtl:ignore*/
    right: calc(-0.375rem - 1px);
    width: 0.375rem;
    height: 0.75rem;
    margin: 0.25rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      /*rtl:begin:ignore*/
      right: 0;
      border-width: 0.375rem 0 0.375rem 0.375rem;
      border-left-color: rgba(0, 0, 0, 0.2);
      /*rtl:end:ignore*/ }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      /*rtl:begin:ignore*/
      right: 1px;
      border-width: 0.375rem 0 0.375rem 0.375rem;
      border-left-color: #fff;
      /*rtl:end:ignore*/ }

.popover-header {
  padding: 0.9375rem 0.9375rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.9375rem 0.9375rem;
  color: #333; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  -ms-touch-action: pan-y;
      touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
        flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
          animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
          animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #2196F3 !important; }

a.bg-primary,
button.bg-primary {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-primary,
    button.bg-primary {
      transition: none; } }
  a.bg-primary:hover, a.bg-primary:focus,
  button.bg-primary:hover,
  button.bg-primary:focus {
    background-color: #0c7cd5 !important; }

.bg-primary-100 {
  background-color: #e9f5fe !important; }

a.bg-primary-100,
button.bg-primary-100 {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-primary-100,
    button.bg-primary-100 {
      transition: none; } }
  a.bg-primary-100:hover, a.bg-primary-100:focus,
  button.bg-primary-100:hover,
  button.bg-primary-100:focus {
    background-color: #b8dffc !important; }

.bg-secondary {
  background-color: #45748a !important; }

a.bg-secondary,
button.bg-secondary {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-secondary,
    button.bg-secondary {
      transition: none; } }
  a.bg-secondary:hover, a.bg-secondary:focus,
  button.bg-secondary:hover,
  button.bg-secondary:focus {
    background-color: #345768 !important; }

.bg-secondary-100 {
  background-color: #ecf1f3 !important; }

a.bg-secondary-100,
button.bg-secondary-100 {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-secondary-100,
    button.bg-secondary-100 {
      transition: none; } }
  a.bg-secondary-100:hover, a.bg-secondary-100:focus,
  button.bg-secondary-100:hover,
  button.bg-secondary-100:focus {
    background-color: #cddadf !important; }

.bg-success {
  background-color: #25b372 !important; }

a.bg-success,
button.bg-success {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-success,
    button.bg-success {
      transition: none; } }
  a.bg-success:hover, a.bg-success:focus,
  button.bg-success:hover,
  button.bg-success:focus {
    background-color: #1c8957 !important; }

.bg-success-100 {
  background-color: #e9f7f1 !important; }

a.bg-success-100,
button.bg-success-100 {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-success-100,
    button.bg-success-100 {
      transition: none; } }
  a.bg-success-100:hover, a.bg-success-100:focus,
  button.bg-success-100:hover,
  button.bg-success-100:focus {
    background-color: #c4e9d9 !important; }

.bg-info {
  background-color: #2cbacc !important; }

a.bg-info,
button.bg-info {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-info,
    button.bg-info {
      transition: none; } }
  a.bg-info:hover, a.bg-info:focus,
  button.bg-info:hover,
  button.bg-info:focus {
    background-color: #2394a2 !important; }

.bg-info-100 {
  background-color: #eaf8fa !important; }

a.bg-info-100,
button.bg-info-100 {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-info-100,
    button.bg-info-100 {
      transition: none; } }
  a.bg-info-100:hover, a.bg-info-100:focus,
  button.bg-info-100:hover,
  button.bg-info-100:focus {
    background-color: #c1eaf0 !important; }

.bg-warning {
  background-color: #f58646 !important; }

a.bg-warning,
button.bg-warning {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-warning,
    button.bg-warning {
      transition: none; } }
  a.bg-warning:hover, a.bg-warning:focus,
  button.bg-warning:hover,
  button.bg-warning:focus {
    background-color: #f26616 !important; }

.bg-warning-100 {
  background-color: #fef3ed !important; }

a.bg-warning-100,
button.bg-warning-100 {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-warning-100,
    button.bg-warning-100 {
      transition: none; } }
  a.bg-warning-100:hover, a.bg-warning-100:focus,
  button.bg-warning-100:hover,
  button.bg-warning-100:focus {
    background-color: #fbd3bd !important; }

.bg-danger {
  background-color: #ef5350 !important; }

a.bg-danger,
button.bg-danger {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-danger,
    button.bg-danger {
      transition: none; } }
  a.bg-danger:hover, a.bg-danger:focus,
  button.bg-danger:hover,
  button.bg-danger:focus {
    background-color: #eb2521 !important; }

.bg-danger-100 {
  background-color: #fdeeee !important; }

a.bg-danger-100,
button.bg-danger-100 {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-danger-100,
    button.bg-danger-100 {
      transition: none; } }
  a.bg-danger-100:hover, a.bg-danger-100:focus,
  button.bg-danger-100:hover,
  button.bg-danger-100:focus {
    background-color: #f8c0c0 !important; }

.bg-white {
  background-color: #fff !important; }

a.bg-white,
button.bg-white {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-white,
    button.bg-white {
      transition: none; } }
  a.bg-white:hover, a.bg-white:focus,
  button.bg-white:hover,
  button.bg-white:focus {
    background-color: #e6e6e6 !important; }

.bg-light {
  background-color: #f0f2f5 !important; }

a.bg-light,
button.bg-light {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-light,
    button.bg-light {
      transition: none; } }
  a.bg-light:hover, a.bg-light:focus,
  button.bg-light:hover,
  button.bg-light:focus {
    background-color: #d1d7e1 !important; }

.bg-light-100 {
  background-color: rgba(255, 255, 255, 0.1) !important; }

a.bg-light-100,
button.bg-light-100 {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-light-100,
    button.bg-light-100 {
      transition: none; } }
  a.bg-light-100:hover, a.bg-light-100:focus,
  button.bg-light-100:hover,
  button.bg-light-100:focus {
    background-color: rgba(230, 230, 230, 0.1) !important; }

.bg-dark {
  background-color: #252b36 !important; }

a.bg-dark,
button.bg-dark {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-dark,
    button.bg-dark {
      transition: none; } }
  a.bg-dark:hover, a.bg-dark:focus,
  button.bg-dark:hover,
  button.bg-dark:focus {
    background-color: #101318 !important; }

.bg-dark-100 {
  background-color: rgba(0, 0, 0, 0.1) !important; }

a.bg-dark-100,
button.bg-dark-100 {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-dark-100,
    button.bg-dark-100 {
      transition: none; } }
  a.bg-dark-100:hover, a.bg-dark-100:focus,
  button.bg-dark-100:hover,
  button.bg-dark-100:focus {
    background-color: rgba(0, 0, 0, 0.1) !important; }

.bg-teal {
  background-color: #26A69A !important; }

a.bg-teal,
button.bg-teal {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-teal,
    button.bg-teal {
      transition: none; } }
  a.bg-teal:hover, a.bg-teal:focus,
  button.bg-teal:hover,
  button.bg-teal:focus {
    background-color: #1d7d74 !important; }

.bg-teal-100 {
  background-color: #e9f6f5 !important; }

a.bg-teal-100,
button.bg-teal-100 {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-teal-100,
    button.bg-teal-100 {
      transition: none; } }
  a.bg-teal-100:hover, a.bg-teal-100:focus,
  button.bg-teal-100:hover,
  button.bg-teal-100:focus {
    background-color: #c5e7e5 !important; }

.bg-indigo {
  background-color: #5C6BC0 !important; }

a.bg-indigo,
button.bg-indigo {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-indigo,
    button.bg-indigo {
      transition: none; } }
  a.bg-indigo:hover, a.bg-indigo:focus,
  button.bg-indigo:hover,
  button.bg-indigo:focus {
    background-color: #4150a8 !important; }

.bg-indigo-100 {
  background-color: #eff0f9 !important; }

a.bg-indigo-100,
button.bg-indigo-100 {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-indigo-100,
    button.bg-indigo-100 {
      transition: none; } }
  a.bg-indigo-100:hover, a.bg-indigo-100:focus,
  button.bg-indigo-100:hover,
  button.bg-indigo-100:focus {
    background-color: #cacdeb !important; }

.bg-purple {
  background-color: #8e70c1 !important; }

a.bg-purple,
button.bg-purple {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-purple,
    button.bg-purple {
      transition: none; } }
  a.bg-purple:hover, a.bg-purple:focus,
  button.bg-purple:hover,
  button.bg-purple:focus {
    background-color: #724db1 !important; }

.bg-purple-100 {
  background-color: #f4f1f9 !important; }

a.bg-purple-100,
button.bg-purple-100 {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-purple-100,
    button.bg-purple-100 {
      transition: none; } }
  a.bg-purple-100:hover, a.bg-purple-100:focus,
  button.bg-purple-100:hover,
  button.bg-purple-100:focus {
    background-color: #d8cdea !important; }

.bg-pink {
  background-color: #f35c86 !important; }

a.bg-pink,
button.bg-pink {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-pink,
    button.bg-pink {
      transition: none; } }
  a.bg-pink:hover, a.bg-pink:focus,
  button.bg-pink:hover,
  button.bg-pink:focus {
    background-color: #f02c63 !important; }

.bg-pink-100 {
  background-color: #feeff3 !important; }

a.bg-pink-100,
button.bg-pink-100 {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-pink-100,
    button.bg-pink-100 {
      transition: none; } }
  a.bg-pink-100:hover, a.bg-pink-100:focus,
  button.bg-pink-100:hover,
  button.bg-pink-100:focus {
    background-color: #fbbfcf !important; }

.bg-yellow {
  background-color: #ffd648 !important; }

a.bg-yellow,
button.bg-yellow {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-yellow,
    button.bg-yellow {
      transition: none; } }
  a.bg-yellow:hover, a.bg-yellow:focus,
  button.bg-yellow:hover,
  button.bg-yellow:focus {
    background-color: #ffcb15 !important; }

.bg-yellow-100 {
  background-color: #fffbed !important; }

a.bg-yellow-100,
button.bg-yellow-100 {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    a.bg-yellow-100,
    button.bg-yellow-100 {
      transition: none; } }
  a.bg-yellow-100:hover, a.bg-yellow-100:focus,
  button.bg-yellow-100:hover,
  button.bg-yellow-100:focus {
    background-color: #fff0ba !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #ddd !important; }

.border-top {
  border-top: 1px solid #ddd !important; }

.border-right {
  border-right: 1px solid #ddd !important; }

.border-bottom {
  border-bottom: 1px solid #ddd !important; }

.border-left {
  border-left: 1px solid #ddd !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #2196F3 !important; }

.border-primary-100 {
  border-color: #e9f5fe !important; }

.border-secondary {
  border-color: #45748a !important; }

.border-secondary-100 {
  border-color: #ecf1f3 !important; }

.border-success {
  border-color: #25b372 !important; }

.border-success-100 {
  border-color: #e9f7f1 !important; }

.border-info {
  border-color: #2cbacc !important; }

.border-info-100 {
  border-color: #eaf8fa !important; }

.border-warning {
  border-color: #f58646 !important; }

.border-warning-100 {
  border-color: #fef3ed !important; }

.border-danger {
  border-color: #ef5350 !important; }

.border-danger-100 {
  border-color: #fdeeee !important; }

.border-white {
  border-color: #fff !important; }

.border-light {
  border-color: #f0f2f5 !important; }

.border-light-100 {
  border-color: rgba(255, 255, 255, 0.1) !important; }

.border-dark {
  border-color: #252b36 !important; }

.border-dark-100 {
  border-color: rgba(0, 0, 0, 0.1) !important; }

.border-teal {
  border-color: #26A69A !important; }

.border-teal-100 {
  border-color: #e9f6f5 !important; }

.border-indigo {
  border-color: #5C6BC0 !important; }

.border-indigo-100 {
  border-color: #eff0f9 !important; }

.border-purple {
  border-color: #8e70c1 !important; }

.border-purple-100 {
  border-color: #f4f1f9 !important; }

.border-pink {
  border-color: #f35c86 !important; }

.border-pink-100 {
  border-color: #feeff3 !important; }

.border-yellow {
  border-color: #ffd648 !important; }

.border-yellow-100 {
  border-color: #fffbed !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.1875rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3125rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 769px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -ms-flex-direction: row !important;
      flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
      flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
      flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
      flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
      justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
      justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
      justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
      justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
      align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
      align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
      align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
      align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
      align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 769px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 769px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.3125rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.3125rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.3125rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.3125rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.3125rem !important; }

.m-2 {
  margin: 0.625rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.625rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.625rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.625rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.625rem !important; }

.m-3 {
  margin: 1.25rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.25rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.25rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1.25rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.25rem !important; }

.m-4 {
  margin: 1.875rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.875rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.875rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.875rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.875rem !important; }

.m-5 {
  margin: 3.75rem !important; }

.mt-5,
.my-5 {
  margin-top: 3.75rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3.75rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3.75rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3.75rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.3125rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.3125rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.3125rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.3125rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.3125rem !important; }

.p-2 {
  padding: 0.625rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.625rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.625rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.625rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.625rem !important; }

.p-3 {
  padding: 1.25rem !important; }

.pt-3,
.py-3 {
  padding-top: 1.25rem !important; }

.pr-3,
.px-3 {
  padding-right: 1.25rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1.25rem !important; }

.pl-3,
.px-3 {
  padding-left: 1.25rem !important; }

.p-4 {
  padding: 1.875rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.875rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.875rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.875rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.875rem !important; }

.p-5 {
  padding: 3.75rem !important; }

.pt-5,
.py-5 {
  padding-top: 3.75rem !important; }

.pr-5,
.px-5 {
  padding-right: 3.75rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3.75rem !important; }

.pl-5,
.px-5 {
  padding-left: 3.75rem !important; }

.m-n1 {
  margin: -0.3125rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.3125rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.3125rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.3125rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.3125rem !important; }

.m-n2 {
  margin: -0.625rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.625rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.625rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.625rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.625rem !important; }

.m-n3 {
  margin: -1.25rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1.25rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1.25rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1.25rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1.25rem !important; }

.m-n4 {
  margin: -1.875rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.875rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.875rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.875rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.875rem !important; }

.m-n5 {
  margin: -3.75rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3.75rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3.75rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3.75rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3.75rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.3125rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.3125rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.3125rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.3125rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.3125rem !important; }
  .m-sm-2 {
    margin: 0.625rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.625rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.625rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.625rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.625rem !important; }
  .m-sm-3 {
    margin: 1.25rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.25rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.25rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.25rem !important; }
  .m-sm-4 {
    margin: 1.875rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.875rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.875rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.875rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.875rem !important; }
  .m-sm-5 {
    margin: 3.75rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3.75rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3.75rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3.75rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3.75rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.3125rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.3125rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.3125rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.3125rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.3125rem !important; }
  .p-sm-2 {
    padding: 0.625rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.625rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.625rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.625rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.625rem !important; }
  .p-sm-3 {
    padding: 1.25rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.25rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.25rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.25rem !important; }
  .p-sm-4 {
    padding: 1.875rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.875rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.875rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.875rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.875rem !important; }
  .p-sm-5 {
    padding: 3.75rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3.75rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3.75rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3.75rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3.75rem !important; }
  .m-sm-n1 {
    margin: -0.3125rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.3125rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.3125rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.3125rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.3125rem !important; }
  .m-sm-n2 {
    margin: -0.625rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.625rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.625rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.625rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.625rem !important; }
  .m-sm-n3 {
    margin: -1.25rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.25rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.25rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.25rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.25rem !important; }
  .m-sm-n4 {
    margin: -1.875rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.875rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.875rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.875rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.875rem !important; }
  .m-sm-n5 {
    margin: -3.75rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3.75rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3.75rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3.75rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3.75rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 769px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.3125rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.3125rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.3125rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.3125rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.3125rem !important; }
  .m-md-2 {
    margin: 0.625rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.625rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.625rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.625rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.625rem !important; }
  .m-md-3 {
    margin: 1.25rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.25rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.25rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.25rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.25rem !important; }
  .m-md-4 {
    margin: 1.875rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.875rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.875rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.875rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.875rem !important; }
  .m-md-5 {
    margin: 3.75rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3.75rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3.75rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3.75rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3.75rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.3125rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.3125rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.3125rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.3125rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.3125rem !important; }
  .p-md-2 {
    padding: 0.625rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.625rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.625rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.625rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.625rem !important; }
  .p-md-3 {
    padding: 1.25rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.25rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.25rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.25rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.25rem !important; }
  .p-md-4 {
    padding: 1.875rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.875rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.875rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.875rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.875rem !important; }
  .p-md-5 {
    padding: 3.75rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3.75rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3.75rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3.75rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3.75rem !important; }
  .m-md-n1 {
    margin: -0.3125rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.3125rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.3125rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.3125rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.3125rem !important; }
  .m-md-n2 {
    margin: -0.625rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.625rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.625rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.625rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.625rem !important; }
  .m-md-n3 {
    margin: -1.25rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.25rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.25rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.25rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.25rem !important; }
  .m-md-n4 {
    margin: -1.875rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.875rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.875rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.875rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.875rem !important; }
  .m-md-n5 {
    margin: -3.75rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3.75rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3.75rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3.75rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3.75rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.3125rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.3125rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.3125rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.3125rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.3125rem !important; }
  .m-lg-2 {
    margin: 0.625rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.625rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.625rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.625rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.625rem !important; }
  .m-lg-3 {
    margin: 1.25rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.25rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.25rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.25rem !important; }
  .m-lg-4 {
    margin: 1.875rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.875rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.875rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.875rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.875rem !important; }
  .m-lg-5 {
    margin: 3.75rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3.75rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3.75rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3.75rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3.75rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.3125rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.3125rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.3125rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.3125rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.3125rem !important; }
  .p-lg-2 {
    padding: 0.625rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.625rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.625rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.625rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.625rem !important; }
  .p-lg-3 {
    padding: 1.25rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.25rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.25rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.25rem !important; }
  .p-lg-4 {
    padding: 1.875rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.875rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.875rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.875rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.875rem !important; }
  .p-lg-5 {
    padding: 3.75rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3.75rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3.75rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3.75rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3.75rem !important; }
  .m-lg-n1 {
    margin: -0.3125rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.3125rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.3125rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.3125rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.3125rem !important; }
  .m-lg-n2 {
    margin: -0.625rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.625rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.625rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.625rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.625rem !important; }
  .m-lg-n3 {
    margin: -1.25rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.25rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.25rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.25rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.25rem !important; }
  .m-lg-n4 {
    margin: -1.875rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.875rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.875rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.875rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.875rem !important; }
  .m-lg-n5 {
    margin: -3.75rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3.75rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3.75rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3.75rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3.75rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.3125rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.3125rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.3125rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.3125rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.3125rem !important; }
  .m-xl-2 {
    margin: 0.625rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.625rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.625rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.625rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.625rem !important; }
  .m-xl-3 {
    margin: 1.25rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.25rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.25rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.25rem !important; }
  .m-xl-4 {
    margin: 1.875rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.875rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.875rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.875rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.875rem !important; }
  .m-xl-5 {
    margin: 3.75rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3.75rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3.75rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3.75rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3.75rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.3125rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.3125rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.3125rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.3125rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.3125rem !important; }
  .p-xl-2 {
    padding: 0.625rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.625rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.625rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.625rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.625rem !important; }
  .p-xl-3 {
    padding: 1.25rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.25rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.25rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.25rem !important; }
  .p-xl-4 {
    padding: 1.875rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.875rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.875rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.875rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.875rem !important; }
  .p-xl-5 {
    padding: 3.75rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3.75rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3.75rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3.75rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3.75rem !important; }
  .m-xl-n1 {
    margin: -0.3125rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.3125rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.3125rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.3125rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.3125rem !important; }
  .m-xl-n2 {
    margin: -0.625rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.625rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.625rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.625rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.625rem !important; }
  .m-xl-n3 {
    margin: -1.25rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.25rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.25rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.25rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.25rem !important; }
  .m-xl-n4 {
    margin: -1.875rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.875rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.875rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.875rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.875rem !important; }
  .m-xl-n5 {
    margin: -3.75rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3.75rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3.75rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3.75rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3.75rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 769px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #2196F3 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0a6ebd !important; }

.text-primary-100 {
  color: #e9f5fe !important; }

a.text-primary-100:hover, a.text-primary-100:focus {
  color: #a0d4fb !important; }

.text-secondary {
  color: #45748a !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #2c4957 !important; }

.text-secondary-100 {
  color: #ecf1f3 !important; }

a.text-secondary-100:hover, a.text-secondary-100:focus {
  color: #bdced5 !important; }

.text-success {
  color: #25b372 !important; }

a.text-success:hover, a.text-success:focus {
  color: #18744a !important; }

.text-success-100 {
  color: #e9f7f1 !important; }

a.text-success-100:hover, a.text-success-100:focus {
  color: #b1e3cd !important; }

.text-info {
  color: #2cbacc !important; }

a.text-info:hover, a.text-info:focus {
  color: #1e818d !important; }

.text-info-100 {
  color: #eaf8fa !important; }

a.text-info-100:hover, a.text-info-100:focus {
  color: #ace3eb !important; }

.text-warning {
  color: #f58646 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #e25b0c !important; }

.text-warning-100 {
  color: #fef3ed !important; }

a.text-warning-100:hover, a.text-warning-100:focus {
  color: #fac3a5 !important; }

.text-danger {
  color: #ef5350 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #de1814 !important; }

.text-danger-100 {
  color: #fdeeee !important; }

a.text-danger-100:hover, a.text-danger-100:focus {
  color: #f5aaaa !important; }

.text-white {
  color: #fff !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-light {
  color: #f0f2f5 !important; }

a.text-light:hover, a.text-light:focus {
  color: #c2cad6 !important; }

.text-light-100 {
  color: rgba(255, 255, 255, 0.1) !important; }

a.text-light-100:hover, a.text-light-100:focus {
  color: rgba(217, 217, 217, 0.1) !important; }

.text-dark {
  color: #252b36 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #060709 !important; }

.text-dark-100 {
  color: rgba(0, 0, 0, 0.1) !important; }

a.text-dark-100:hover, a.text-dark-100:focus {
  color: rgba(0, 0, 0, 0.1) !important; }

.text-teal {
  color: #26A69A !important; }

a.text-teal:hover, a.text-teal:focus {
  color: #186860 !important; }

.text-teal-100 {
  color: #e9f6f5 !important; }

a.text-teal-100:hover, a.text-teal-100:focus {
  color: #b3e0dc !important; }

.text-indigo {
  color: #5C6BC0 !important; }

a.text-indigo:hover, a.text-indigo:focus {
  color: #3a4896 !important; }

.text-indigo-100 {
  color: #eff0f9 !important; }

a.text-indigo-100:hover, a.text-indigo-100:focus {
  color: #b7bce4 !important; }

.text-purple {
  color: #8e70c1 !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #67459f !important; }

.text-purple-100 {
  color: #f4f1f9 !important; }

a.text-purple-100:hover, a.text-purple-100:focus {
  color: #cabbe2 !important; }

.text-pink {
  color: #f35c86 !important; }

a.text-pink:hover, a.text-pink:focus {
  color: #ee1551 !important; }

.text-pink-100 {
  color: #feeff3 !important; }

a.text-pink-100:hover, a.text-pink-100:focus {
  color: #faa7bd !important; }

.text-yellow {
  color: #ffd648 !important; }

a.text-yellow:hover, a.text-yellow:focus {
  color: #fbc200 !important; }

.text-yellow-100 {
  color: #fffbed !important; }

a.text-yellow-100:hover, a.text-yellow-100:focus {
  color: #ffeaa1 !important; }

.text-body {
  color: #333 !important; }

.text-muted {
  color: #999 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #ccc;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #eee !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #ddd; }
  .table .thead-dark th {
    color: inherit;
    border-color: #ddd; } }
