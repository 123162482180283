.swal2-title {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5715;
    text-align: center;
    position: relative;
    word-wrap: break-word;
    margin-top: 0.3125rem;
    margin-bottom: 0;
}
.swal2-styled.swal2-confirm {
    color: #fff;
    background-color: #2196f3;
    font-weight: 400;
    border: 1px solid #2196f3;
}